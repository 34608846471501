import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DashboardService } from 'src/services/dashboard.service/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { } from 'selenium-webdriver';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CommonService } from 'src/services/common-services/common.service';

@Component({
  selector: 'app-dashboard-seasons-must-have',
  templateUrl: './dashboard-seasons-must-have.component.html',
  styleUrls: ['./dashboard-seasons-must-have.component.css']
})
export class DashboardSeasonsMustHaveComponent implements OnInit {
  @Input() offerSection: any = {};
  @Output() openOfferPageCallBack = new EventEmitter();
  @Output() showProductDetailsCallBack = new EventEmitter();
  @Output() addToCartCallBack = new EventEmitter();
  customOptions: OwlOptions;
  seasonMustHaveList: any = [];
  displayType: number = 1;
  constructor(private _dashboardService: DashboardService,
    private toastr: ToastrService,
    public _commonService: CommonService) {
    this.displayType = 2;
    this.customOptions = {
      loop: false,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: true,
      navSpeed: 700,
      //autoplay: true,
      navText: ['', ''],
      responsive: {
        0:
        {
          items: 1
        },
        575:
        {
          items: 2
        }, 840:
        {
          items: 4
        }, 1000:
        {
          items: 6
        },
        1440:
        {
          items: 8
        }
      },
      nav: true
    }
  }

  ngOnInit() {

  }

  addToCart(product) {
    this.addToCartCallBack.emit(product)
  }

  openOfferPage(offer) {
    this.openOfferPageCallBack.emit(offer);
  }

  showDetail(product) {
    this.showProductDetailsCallBack.emit(product);
  }

  openProductDetails(product) {
    this.showProductDetailsCallBack.emit(product);
  }
  openOffer(mustHave) {
    this.openOfferPageCallBack.emit(mustHave);
  }
}
