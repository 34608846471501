import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { YessCategoryComponent } from 'src/yess-pages/yess-category/yess-category.component';
import { YessProductDetailsComponent } from 'src/yess-pages/yess-product-details/yess-product-details.component';
import { YessLayoutComponent } from 'src/yess-pages/yess-layout/yess-layout/yess-layout.component';
import { YessAccountLayoutComponent } from 'src/yess-pages/yes-account/yes-account-layout/yess-account-layout/yess-account-layout.component';
import { YessMyAccountPageComponent } from 'src/yess-pages/yes-account/pages/yess-my-account-page/yess-my-account-page.component';
import { YessDashboardComponent } from 'src/yess-pages/yess-home/yess-dashboard/yess-dashboard.component';
import { MyOrderComponent } from 'src/yess-pages/yes-account/pages/my-order/my-order.component';
import { AuthGuardService } from 'src/services/auth-gaurd-service/auth-guard.service';
import { YessNoPageFoundComponent } from 'src/yess-pages/yess-no-page-found/yess-no-page-found/yess-no-page-found.component';
import { AuthPublicGuardService } from 'src/services/auth-public-gaurd-service/auth-public-guard.service';
import { YessOfferListComponent } from 'src/yess-pages/yess-home/yess-offer-list/yess-offer-list.component';
import { YessPayMsgComponent } from 'src/yess-pages/yess-pay-response/yess-pay-msg/yess-pay-msg.component';

const routes: Routes = [
  { path: 'pay-response/:msg', component: YessPayMsgComponent,data : { title: 'Pay-status'} },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: '', component: YessLayoutComponent,
    children: [
      { path: 'home', component: YessDashboardComponent,data : { title: 'Home'} },
      { path: 'offers', component: YessOfferListComponent },
      { path: 'offers/:Type/:Name', component: YessCategoryComponent,data : { title: 'Offer'} },
      { path: 'offers/:Type/:Name/:Id', component: YessCategoryComponent,data : { title: 'Offer'} },
      
      //{ path: 'category', component: YessCategoryComponent },
      { path: 'category/:Type/:Name', component: YessCategoryComponent,data : { title: 'Category'} },
      { path: 'category/:Type/:Name/:Id', component: YessCategoryComponent,data : { title: 'Category'} },
      { path: 'product-detail/:id/:catId', component: YessProductDetailsComponent,data : { title: 'Product Details'} },
      { path: 'login', component: YessDashboardComponent,
      canActivate: [AuthPublicGuardService],data : { title: 'Login'} },
      {
        path: '',
        component: YessAccountLayoutComponent,
        canActivate: [AuthGuardService],
        children: [
          { path: 'my-account', component: YessMyAccountPageComponent, canActivate: [AuthGuardService],data : { title: 'My Account',metaDesc:'My Account'} },
          { path: 'my-orders', component: MyOrderComponent, canActivate: [AuthGuardService],data : { title: 'My Orders',metaDesc:'My Orders'}},
        ]
      },
      {
        path: 'about',
        loadChildren: 'src/yess-pages/yes-about/yess-about.module#YessAboutModule'
        ,data : { title: 'About-us',metaDesc:'About Us'}
      },
      {
        canActivate: [AuthGuardService],
        path: 'cart',
        loadChildren: 'src/yess-pages/yes-cart/yess-cart.module#YessCartModule'
        ,data : { title: 'Cart'}
      },
      { path: 'page-not-found', component: YessNoPageFoundComponent ,data : { title: 'Page Not found',metaDesc:'Page Not Found'}},
      { path: '**', redirectTo:'page-not-found' },
    ]
  },
]

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 100] // [x, y]
    }),
  ],
  providers: [],
  exports: [RouterModule]

})
export class YessAppRoutingModule { }
