import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DashboardService } from 'src/services/dashboard.service/dashboard.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dashboard-bank-offers',
  templateUrl: './dashboard-bank-offers.component.html',
  styleUrls: ['./dashboard-bank-offers.component.css']
})
export class DashboardBankOffersComponent implements OnInit {
  @Input() businessId: number = 0;
  @Output() ImageClickCallBack = new EventEmitter();
  bankOfferList: any = [];
  constructor(private _dashboardService: DashboardService,
    private toastr: ToastrService, ) { }

  ngOnInit() {
    this.getBankOffers(this.businessId);
  }
  getBankOffers(businessId) {
    this._dashboardService.getBankOffers(businessId).subscribe(res => {
      let result: any = res;
      if (result.IsError) {
        this.bankOfferList = this.addDummyObjects(this.bankOfferList, 4);

      } else {
        this.bankOfferList = result.ResponseMessage;
        if (this.bankOfferList.length < 4) {
          const length = 4 - this.bankOfferList.length;
          this.bankOfferList = this.addDummyObjects(this.bankOfferList, length);
        }
      }
    }, err => {
      this.toastr.error("Something went wrong while getting Bank Offers");

    })
  }
  ImageClick(offer) {
    if (offer.OfferId) {
      this.ImageClickCallBack.emit(offer);
    }
  }
  addDummyObjects(list: any = [], doodleWantToAdd: number) {
    for (var i = 1; i <= doodleWantToAdd; i++) {
      const obj = {
        OfferId: 0,
        OfferImage: "assets/images/dev_images/noImageAvailable.jpg",
        OfferName: "ICICI",
      }
      list.push(obj);
    }
    return list;
  }
}
