import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-order-popup',
  templateUrl: './confirm-order-popup.component.html',
  styleUrls: ['./confirm-order-popup.component.css']
})
export class confirmOrderPopupComponent implements OnInit {
  @Input() message: string = "";
  @Input() Confirm_btn_text: string = "Continue";
  @Input() model_title: string = "Confirmation";

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
    // if (this.Confirm_btn_text == null || this.Confirm_btn_text == "") {
    //   this.Confirm_btn_text = "Continue";
    // }
    // if (this.model_title == null || this.model_title == "") {
    //   this.model_title = "Confirmation";
    // }
  }
  closePopUp() {
    this.activeModal.dismiss();
  }
  continue() {
    this.activeModal.close();
  }

}
