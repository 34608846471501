import { Injectable, Inject } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { CookieStorageService } from '../cookie-service/cookie.service';
import { SignInSignUpPopupComponent } from 'src/popups/sign-in-sign-up-popup/sign-in-sign-up-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DOCUMENT } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(private router: Router,
    private _cookieService: CookieStorageService,
    private modalService: NgbModal,
    @Inject(DOCUMENT) private document: Document) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    var userId = this._cookieService.getBuyerId();
    if (userId) {
      return true;
    }
    else {
      this.router.navigate(['/home']).then(res => {
        this.loginSignUp(1);
      })
      return false;
    }
  }

  loginSignUp(activeTab) {
    const modalRef = this.modalService.open(SignInSignUpPopupComponent,
      { centered: true, backdrop: 'static', keyboard: false, size: 'lg', windowClass: "loginModal" }
    );
    modalRef.componentInstance.activeTab = activeTab;
    modalRef.result.then((data) => {
      if (data && data.action) {
        this.document.location.reload();
      }
    }, (reason) => {
    });
  }
}
