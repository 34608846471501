import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { LayoutService } from 'src/services/layout.service/layout.service';
import { CategoryModel } from 'src/models/layout-models/layout.models';
import { SessionStorageService } from 'src/services/session-storage-service/session.storage.service';
import { HeaderCategoryDropdownComponent } from 'src/common-components/header-category-dropdown/header-category-dropdown.component';
@Component({
  selector: 'app-yess-header',
  templateUrl: './yess-header.component.html',
  styleUrls: ['./yess-header.component.css']
})
export class YessHeaderComponent implements OnInit {
  @ViewChild(HeaderCategoryDropdownComponent, { static: false }) headerCategoryDropDown: HeaderCategoryDropdownComponent;
  //categories: CategoryModel[] = [];
  cartItems: string = '0';
  categories: any = [];
  isMobileViewMenu: boolean=false;
  constructor(private _router: Router,
    private _layoutService: LayoutService,
    private _sessionStorageService: SessionStorageService) { }
  ngOnInit() {

    this.getAllCategories();
  }
  getAllCategories() {
    this._layoutService.loadMainCategory().subscribe(res => {
      let result: any = res;
      if (!result.IsError) {
        setTimeout(() => {
          this.categories = result.Category;
          // this.categories = result.sp_GetAllCategoryList1s;
        }, 0);

      }

    }, err => {

    })
  }
  openMainCategoryProducts(mainCat) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "Type": 'Category',
        "Data": JSON.stringify({
          "Id": mainCat.CategoryId,
          "Name": mainCat.CategoryName,
        }),
      }
    };
    //this._router.navigate(['category'], navigationExtras);
    this._router.navigate(['category/Category/'+mainCat.CategoryName+'/'+mainCat.CategoryId]);
  }
  openSubCategory1Products(mainCat) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "Type": 'SubCategory',
        "Data": JSON.stringify({
          "Id": mainCat.SubCategory.SubCategoryId,
          "Name": mainCat.SubCategory.SubCategoryName,
        }),
      }
    };
    this._router.navigate(['category'], navigationExtras);
    this._router.navigate(['category/SubCategory/'+mainCat.SubCategory.SubCategoryName+'/'+mainCat.SubCategory.SubCategoryId]);
  }
  openHeaderCategory() {
    this.isMobileViewMenu=true;
    this.headerCategoryDropDown.openCloseCategoryMenu(false);
  }
  trackBy(index: number, item) {
    return index;
  }

}
