import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-yess-pay-msg',
  templateUrl: './yess-pay-msg.component.html',
  styleUrls: ['./yess-pay-msg.component.css']
})
export class YessPayMsgComponent implements OnInit {

  txStatus:string='';
  constructor(
    private _activatedroute: ActivatedRoute) {

    
    this._activatedroute.params.subscribe((params: any) => {        
      this.txStatus = params.msg?params.msg.trim().toUpperCase():'';
    });
   }

  ngOnInit() {
  }

}
