import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from 'src/services/dashboard.service/dashboard.service';
import { BannerModel } from 'src/models/dashboard-models/dashboard.models';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-main-slider',
  templateUrl: './main-slider.component.html',
  styleUrls: ['./main-slider.component.css']
})
export class MainSliderComponent implements OnInit {
  @Input() businessId: number = 0;
  @Output() MainSliderImageClickCallBack = new EventEmitter();
  slidingBanners: BannerModel[] = [];
  customOptions: OwlOptions;
  activeBanner=0;
  constructor(private toastr: ToastrService,
    private _dashboardService: DashboardService, ) {
    this.customOptions = {
      loop: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: false,
      autoplay: true,
      navSpeed: 700,
      smartSpeed: 700,
      //navText: ['Back', 'Next'],
      navText: ['', ''],
      responsive: {
        0: {
          items: 1
        },
      },
      nav: true
    }
  }

  ngOnInit() {
    this.getAllBanners(this.businessId)
  }
  getAllBanners(businessId) {
    this._dashboardService.loadBanner('OFFER',businessId).subscribe(res => {
      let result: any = res;
      if (!result.IsError) {
        this.slidingBanners = result.ResponseMessage;
      } else {
        this.toastr.error(result.ErrorMessage);
      }
    }, err => {
      this.toastr.error("Something went wrong while getting banners ");
    })

  }
  imageClick(banner) {
    this.MainSliderImageClickCallBack.emit(banner);
  }
}
