import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment'
import { HttpService } from '../http.service/httpService';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  serverURL = environment.baseUrl;
  constructor(private _httpService: HttpService) { }
  loadMainCategory() {
    return this._httpService.get("api/ItemDetails/GetAllCategoryList");
  }
  getCartCount(SessionId, BuyerId) {
    return this._httpService.post("api/Home/EcomGetCartCount?SessionId=" + SessionId + "&BuyerId=" + BuyerId);
  }
  loadAllCity() {
    return this._httpService.post("api/Home/LoadAllCity");
  }
  LoadArea(searchText, selectedCity) {
    return this._httpService.post("api/Home/LoadArea?searchText=" + searchText + "&cityname=" + selectedCity);
  }
  LoadSearchData(StoreId,searchText) {
    return this._httpService.post("api/Home/LoadSearchData?StoreId="+StoreId+"&searchText=" + searchText);
  }
  getDefaultStore(pincode) {
    return this._httpService.get("api/Home/LoadStore?PINCode=" + pincode);
  }
  getStaticHtmlPageByType(type) {
    return this._httpService.get("api/StaticHtmlPages/GetStaticHtmlPagesByType?PageType=" + type);
  }
}
