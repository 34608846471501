import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment'
import { HttpService } from '../http.service/httpService';

@Injectable({
  providedIn: 'root'
})
export class ProductDetailService {
  serverURL = environment.baseUrl;
  constructor(private _httpService: HttpService) { }
  getProductDetailById(productId: any,businessId: number) {
    return this._httpService.get("api/ItemDetails/LoadItem?ItemCode=" + productId + "&StoreId=" + businessId);
  }
}

