import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHTMLPipe } from 'src/shared/pipes/safe-html.pipe';
import { SomePipe } from 'src/shared/pipes/some.pipe';
import { SafeURLPipe } from 'src/shared/pipes/safe-url.pipe';
import { FilterPipe } from 'src/shared/pipes/filter.pipe';
import { ProductDetailSimilorProductCardComponent } from 'src/common-components/product-detail-similor-product-card/product-detail-similor-product-card.component';



@NgModule({
  declarations: [
    SafeHTMLPipe,
    SomePipe,
    SafeURLPipe,
    FilterPipe,
  ],
  imports: [
    CommonModule,
  ],
  exports:  [
    SafeHTMLPipe,
    SomePipe,
    SafeURLPipe,
    FilterPipe,
  ],
})
export class YessPipeModule {
  // static forRoot() {
  //   return {
  //     ngModule: YessPipeModule,
  //     providers: [],
  //   };
  // }
}
