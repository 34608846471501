import { Injectable } from '@angular/core';
import { HttpService } from '../http.service/httpService';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
import { CartFilter, cartProductModel } from 'src/models/cart-models/cart.models';
import { YessConstants } from 'src/shared/constants';
import { CookieStorageService } from '../cookie-service/cookie.service';
import { CategoryIdCode } from 'src/models/category-models/category.models';
@Injectable({
  providedIn: 'root'
})
export class CartService {
  cartFilter: CartFilter = {};
  servicePaymentDetails: any = {};  
  serviceItemList: cartProductModel[] = [];
  paymentDetailsChange = new Subject<any>();
  WorldFoodChange = new Subject<any>();
  ItemListChange = new Subject<any>();
  GetCatIdCatCode:CategoryIdCode={};
  StoreId: any;
  serverURL = environment.baseUrl;
  constructor(private _httpService: HttpService,
    private _cookieStorageService: CookieStorageService) {
    this.StoreId = this._cookieStorageService.getStoreId();
    const localbuyerId = _cookieStorageService.getBuyerId();
    this.cartFilter = {
      SessionId: this._cookieStorageService.getSessionId(),
      BuyerId: localbuyerId ? localbuyerId : 0,
      PageNumber: 1,
      PageSize: YessConstants.PageSize,
    }
    this.getCartItems(this.cartFilter);
    //this.WorldFoodChange.subscribe(res => {
      this.GetCategoryIdByCatCode("World_Food").subscribe(res => {
        var result: any = res;
        console.log("result",result);
        this.GetCatIdCatCode.CategoryId = result.CategoryId;
        this.GetCatIdCatCode.CategoryCoe=result.CategoryCode;
        
        this.WorldFoodChange.subscribe(res => {
        let WorldFoodCatItems = this.serviceItemList.filter(a=>a.CategoryId==this.GetCatIdCatCode.CategoryId.toString());
        if(WorldFoodCatItems.length>0)
        {
          this.servicePaymentDetails.WorldFoodCharge = 200;
          this.paymentDetailsChange.next();
        }
      });
    });
  }
 
  getCartItems(cartFilter: CartFilter) {
    this.getAllCartsItems(cartFilter.SessionId, cartFilter.BuyerId).subscribe(res => {
      let result: any = res;
      if (!result.IsError) {
        //this.cartItems = result.ResponseMessage && result.ResponseMessage.CartItems ? result.ResponseMessage.CartItems : [];
        //this.totalRecord = result.ResponseMessage && result.ResponseMessage.CartItemCount[0] && result.ResponseMessage.CartItemCount[0].Column1 ? result.ResponseMessage.CartItemCount[0].Column1 : 0;
        this.serviceItemList = result.ResponseMessage ? result.ResponseMessage : [];
        this.ItemListChange.next();
      }
    })
  }
  getAllCartsItems(SessionId: any, BuyerId: any) {
    return this._httpService.post("api/EcomCart/showcart?SessionID=" + SessionId + "&BuyerID=" + BuyerId);
  }
  deleteCartItem(sessionId, BuyerId, ProductId) {
    return this._httpService.post("api/EcomCart/DeleteFromCart?SessionId=" + sessionId + "&BuyerId=" + BuyerId + "&ProductId=" + ProductId);
  }
  getPaymentDetail(SessionId: any, BuyerId: any) {
    return this._httpService.post("api/Home/EcomGetCartTotal?SessionId=" + SessionId + "&BuyerId=" + BuyerId);
  }
  AddOrderRequest(data: any = {}) {
    return this._httpService.postGetCart("api/OrderRequest/AddOrderRequest", data);
  }
  getOrderTransactionDetails(data) {
    return this._httpService.postGetCart("api/OrderRequest/PaymentStatus", data);
  }
  ShowOfferList() {
    return this._httpService.get("api/GetAllCouponcodes");
  }
  ShowDeliverySlot() {
    return this._httpService.get("api/DeliveryAPI/DeliverySlot");
  }
  GetItemListByItemIds(data, StoreId) {
    return this._httpService.get("ItemDetails/GetItemList?ItemIds=" + data + "&StoreId=" + StoreId);
  }
  CouponValidater(UserId, CouponId) {
    return this._httpService.get("api/OrderRequest/CouponValidater?UserId=" + UserId + "&CouponId=" + CouponId);
  }
  GetCategoryIdByCatCode(data: any) {
    debugger
    return this._httpService.get("api/Home/CategoryIdByCatCode?CategoryCode=" + data);
  }
}
