import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(private _http: HttpClient) { }
  get(url: string) {
    
    return this._http.get(environment.baseUrl + url).pipe(map(res => {
      return res;
    })
    );
  }
  post(url: string) {
    
    return this._http.post(environment.baseUrl + url, null).pipe(map(res => {
      return res;
    })
    );
  }
  postGetCart(url: string, data: any) {
    
    let myheader: HttpHeaders = new HttpHeaders();
    myheader = myheader.append('Content-Type', 'application/json');
    // myheader = myheader.append('Cache-Control',  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0');
    // myheader = myheader.append('Pragma', 'no-cache');
    // myheader = myheader.append('Expires', '0');
    return this._http.post(environment.baseUrl + url, data, { headers: myheader, responseType: 'json' }).pipe(map(res => {
      return res;
    })
    );
  }
}
