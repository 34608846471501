import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { YessEnums } from 'src/shared/enum/enum';

@Component({
  selector: 'app-category-filters-side-bar',
  templateUrl: './category-filters-side-bar.component.html',
  styleUrls: ['./category-filters-side-bar.component.css']
})
export class CategoryFiltersSideBarComponent implements OnInit {
  yesEnum: any = {};
  isBrandSelected: boolean = false;
  isMenufactureSelected: boolean = false;
  isCategorySelected: boolean = false;
  @Input() categoriesList: any = [];
  @Input() brandsList: any = [];
  @Input() menufacturesList: any = [];
  selectedFilters: any = {}
  @Output() onSelectionCallback = new EventEmitter();

  searchBrand: string = "";
  searchManufacture: string = "";
  searchCategory: string="";


  constructor(private _yessEnums: YessEnums) {
    this.selectedFilters = {
      categoriesList: [],
      brandsList: [],
      menufacturesList: [],
    }
    this.yesEnum = this._yessEnums.FiltersEnum;
  }

  ngOnInit() {
  }
  ngOnAfterInit() {

  }
  ngOnChanges(changes) {
    this.selectedFilters = {
      categoriesList: [],
      brandsList: [],
      menufacturesList: [],
    }
  }
  selectCategory(cat, filterName) {
    this.selectedFilters = {
      categoriesList: [],
      brandsList: [],
      menufacturesList: [],
    }
    //let strengthsIndex = this.categoriesList.findIndex(b => b.Id === cat.Id);
    //this.selectedFilters.categoriesList = this.categoriesList[strengthsIndex];
    this.selectedFilters.categoriesList = this.categoriesList.filter(opt => opt.Id === cat.Id);
    this.onSelectionCallback.emit(this.selectedFilters);
  }
  selectedFilter(filterName) {
    switch (filterName) {
      case this.yesEnum.Brand:
        this.selectedFilters.brandsList = this.getCheckedList(this.brandsList);
        if (this.selectedFilters.brandsList && this.selectedFilters.brandsList.length > 0) {
          this.isBrandSelected = true;
        }
        else {
          this.isBrandSelected = false;
        }
        break;
      case this.yesEnum.Manufacturer:
        this.selectedFilters.menufacturesList = this.getCheckedList(this.menufacturesList);
        if (this.selectedFilters.menufacturesList && this.selectedFilters.menufacturesList.length > 0) {
          this.isMenufactureSelected = true;
        }
        else {
          this.isMenufactureSelected = false;
        }
        break;
      case this.yesEnum.Category:
        this.selectedFilters.categoriesList = this.getCheckedList(this.categoriesList);
        if (this.selectedFilters.categoriesList && this.selectedFilters.categoriesList.length > 0) {
          this.isCategorySelected = true;
        }
        else {
          this.isCategorySelected = false;
        }
        break;
    }
    this.onSelectionCallback.emit(this.selectedFilters);
  }
  getCheckedList(listArray: any = []) {
    return listArray.filter(opt => opt.isChecked === true);
  }
  clearBrandFilter(){
    this.brandsList.every(a=>{a.isChecked=false; return true;});
    this.selectedFilters.brandsList = [];
    this.isBrandSelected = false;
    this.onSelectionCallback.emit(this.selectedFilters);
  }
  clearManufacturerFilter(){
    this.menufacturesList.every(a=>{a.isChecked=false; return true;});
    this.selectedFilters.menufacturesList = [];
    this.isMenufactureSelected = false;
    this.onSelectionCallback.emit(this.selectedFilters);
  }
  clearCategoryFilter(){
    this.categoriesList.every(a=>{a.isChecked=false; return true;});
    this.selectedFilters.categoriesList = [];
    this.isCategorySelected = false;
    this.onSelectionCallback.emit(this.selectedFilters);
  }
}
