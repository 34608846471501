import { Injectable } from '@angular/core';
import { CookieService, CookieOptions } from 'ngx-cookie';
@Injectable({
  providedIn: 'root'
})
export class CookieStorageService {
  constructor(private _cookieService: CookieService) {
  }
  getUser() {
    const data = this._cookieService.get("yess_user");
    if (data) {
      return JSON.parse(data);
    } else {
      return {
        Userid: null,
      };
    }
  }
  setUser(UserData: any) {
    this._cookieService.put("yess_user", JSON.stringify(UserData));
  }
  setStoreId(Id: any) {
    this._cookieService.put("storeId", Id);
  }
  setStoreInfo(data: any) {
    // let info={
    //   "Latitude":data.Latitude,
    //   "Logitude":data.Logitude,
    //   "StoreId":data.StoreId,
    //   "DeliveryRangeKm":data.DeliveryRangeKm,
    //   "StoreName":data.StoreName,
    //   "Address":data.Address,
    // }
    this._cookieService.put("storeInfo",  JSON.stringify(data));
  }
  setCityAndPin(data: any) {
    this._cookieService.put("storeAddress", JSON.stringify(data));
  }
  getCityAndPin() {
    const storeAddress = this._cookieService.get("storeAddress");
    if (storeAddress) {
      return JSON.parse(storeAddress);
    } else {
      return null
    }
  }

  getStoreId() {
    return this._cookieService.get("storeId");
  }
  getStoreInfo() {
    const data= this._cookieService.get("storeInfo");
    if (data) {
      let result = JSON.parse(data);
      result.Latitude=+result.Latitude;
      result.Logitude=+result.Logitude;
      return result;
    } else {
      return { };
    }
  }

  setSessionId(Id: any) {
    const dateNow = new Date();
    dateNow.setDate(dateNow.getDate() + 3);
    let options: CookieOptions = {
      expires: dateNow,
    }
    this._cookieService.put("ssid", Id, options);
  }
  getSessionId() {
    return this._cookieService.get("ssid");
  }

  setFirstTimeIndicator() {
    this._cookieService.put("isFirstTime", "1");
  }
  getFirstTimeIndicator() {
    return this._cookieService.get("isFirstTime");
  }

  clearAllCookies() {
    this._cookieService.removeAll();
  }
  getBuyerId() {
    const id = this.getUser().Userid;
    if (id) {
      return id;
    } else {
      return 0;
    }
  }
}
