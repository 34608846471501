import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment'
import { HttpService } from '../http.service/httpService';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  serverURL = environment.baseUrl;
  
  constructor(private _httpService: HttpService) {
   
   }

  getAllProductsByCategory(filter: any) {
    return this._httpService.postGetCart("api/Home/LoadProductsByMainCatId", filter);
  }
  getAllProductsBySubCategory(filter) {
    return this._httpService.postGetCart("api/Home/LoadProductsBySubCatId", filter);
  }
  addToCart(data: object) {
    var jsonData = JSON.stringify(data);
    console.log(jsonData)
    return this._httpService.postGetCart("api/EcomCart/AddToCart", data);
  }
  getAllProducts(filter: any) {
    return this._httpService.postGetCart("api/Home/GetProducts", filter);
  }
  
}

