import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DashboardService } from 'src/services/dashboard.service/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-dashboard-brand-store',
  templateUrl: './dashboard-brand-store.component.html',
  styleUrls: ['./dashboard-brand-store.component.css']
})
export class DashboardBrandStoreComponent implements OnInit {
  @Input() businessId: number = 0;
  @Output() brandImageClickCallBack = new EventEmitter();
  allStoreBrandList: any = [];
  customOptions: OwlOptions;
  constructor(private _dashboardService: DashboardService,
    private toastr: ToastrService, ) {
    this.customOptions = {
      loop: false,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: true,
      navSpeed: 700,
      //autoplay: true,
      navText: ['', ''],
      responsive: {
        0:
        {
          items: 1
        },
        575:
        {
          items: 2
        }, 840:
        {
          items: 3
        }, 1000:
        {
          items: 6
        },
        1440:
        {
          items: 7
        }
      },
      nav: true
    }
  }

  ngOnInit() {
    this.getAllBrandsStore(this.businessId);
  }
  getAllBrandsStore(businessId) {
    const reqData = {
      'Storeid': businessId,
      'ProductType': 0,
    }
    this._dashboardService.getAllHomePageBrands().subscribe(res => {
      let result: any = res;
      
      if (result.IsError) {
        this.toastr.error(result.ErrorMessage);
        this.allStoreBrandList = [];
      } else {
        var localallStoreBrandList: [] = result.ResponseMessage;
        this.allStoreBrandList = localallStoreBrandList.slice(0, 7)
      }
    }, err => {
      this.toastr.error("Something went wrong while getting  Store Brands");

    });

  }
  brandImageClick(cat) {
    this.brandImageClickCallBack.emit(cat);
  }
}
