import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: "[NoWhiteSpaceFirst]"
})
export class NoWhiteSpaceFirst {
  constructor(private el: ElementRef) {
  }
  @HostListener('keypress', ['$event']) onInput(e) {
    if ((e.which === 32 && this.el.nativeElement.value.length == 0)) {
      e.preventDefault();
    }
  }
}


@Directive({
  selector: "[NoWhiteSpace]"
})
export class NoWhiteSpace {
  constructor(private el: ElementRef) {
  }
  @HostListener('keypress', ['$event']) onInput(e) {
    if (e.which === 32)
      e.preventDefault();
  }
}

@Directive({
  selector: "[DigitOnly]"
})
export class DigitOnly {// it include only digits.
  constructor(private el: ElementRef) {
  }
  @HostListener('keypress', ['$event']) onInput(e) {
    if (e.which > 31 && (e.which < 48 || e.which > 57)) {
      e.preventDefault();
    }
  }
}

@Directive({
  selector: "[characterOnly]"//allow alphanumeric with -/,
})
export class CharacterOnly {
  constructor(private el: ElementRef) {
  }
  @HostListener('keypress', ['$event']) onInput(e) {
    if (!((e.which >= 65 && e.which <= 90) || (e.which >= 97 && e.which <= 122) || e.which == 32)) {
      e.preventDefault();
    }
  }
}

@Directive({
  selector: "[alphanumeric]"//allow alphanumeric with -/,
})
export class Alphanumeric {
  constructor(private el: ElementRef) {
  }
  @HostListener('keypress', ['$event']) onInput(e) {
    if (e.which == 32 || e.which == 41 || e.which == 40 || (e.which > 43 && e.which < 58) || (e.which > 64 && e.which < 91) || (e.which > 96 && e.which < 123)) {

    } else {
      e.preventDefault();
    }
  }
}

@Directive({
  selector: '[blockCopyPaste]'
})
export class BlockCopyPasteDirective {
  constructor() { }

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }
}















