import { BrowserModule, Meta, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { SharedModule } from './shared.module';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CookieModule } from 'ngx-cookie';
import { RatingModule } from 'ng-starrating';
import { NgSelectModule } from '@ng-select/ng-select';


import { YessAppComponent } from './yess-app.component';
import { YessAppRoutingModule } from './yess-app-routing.module';
import { YessLayoutComponent } from 'src/yess-pages/yess-layout/yess-layout/yess-layout.component';
import { YessHeaderComponent } from 'src/yess-pages/yess-layout/yess-header/yess-header.component';
import { YessFooterComponent } from 'src/yess-pages/yess-layout/yess-footer/yess-footer.component';
import { CategoryProductCardComponent } from 'src/common-components/category-product-card/category-product-card.component';
import { YessCategoryComponent } from 'src/yess-pages/yess-category/yess-category.component';
import { YessProductDetailsComponent } from 'src/yess-pages/yess-product-details/yess-product-details.component';
import { HeaderCategoryDropdownComponent } from 'src/common-components/header-category-dropdown/header-category-dropdown.component';
import { SecondTopBarComponent } from 'src/common-components/second-top-bar/second-top-bar.component';
import { FirstTopBarComponent } from 'src/common-components/first-top-bar/first-top-bar.component';
import { SignInSignUpPopupComponent } from 'src/popups/sign-in-sign-up-popup/sign-in-sign-up-popup.component';

import { YessAccountLayoutComponent } from 'src/yess-pages/yes-account/yes-account-layout/yess-account-layout/yess-account-layout.component';
import { YessMyAccountPageComponent } from 'src/yess-pages/yes-account/pages/yess-my-account-page/yess-my-account-page.component';
import { FacebookGoogleLoginComponent } from 'src/common-components/facebook-google-login/facebook-google-login.component';
import { FacebookGoogleSignupComponent } from 'src/common-components/facebook-google-signup/facebook-google-signup.component';
import { ProductDetailSimilorProductCardComponent } from 'src/common-components/product-detail-similor-product-card/product-detail-similor-product-card.component';
import { YessDashboardComponent } from 'src/yess-pages/yess-home/yess-dashboard/yess-dashboard.component';
import { AllCategoriesComponent } from 'src/yess-pages/yess-home/components/all-categories/all-categories.component';
import { DasboardOffersComponent } from 'src/yess-pages/yess-home/components/dasboard-offers/dasboard-offers.component';
import { DashboardBankOffersComponent } from 'src/yess-pages/yess-home/components/dashboard-bank-offers/dashboard-bank-offers.component';
import { DashboardFruitAndVegStoreComponent } from 'src/yess-pages/yess-home/components/dashboard-fruit-and-veg-store/dashboard-fruit-and-veg-store.component';
import { DashboardDrinkAndBaveragesComponent } from 'src/yess-pages/yess-home/components/dashboard-drink-and-baverages/dashboard-drink-and-baverages.component';
import { DashboardCleanHouseHoldComponent } from 'src/yess-pages/yess-home/components/dashboard-clean-house-hold/dashboard-clean-house-hold.component';
import { DashboardBrandStoreComponent } from 'src/yess-pages/yess-home/components/dashboard-brand-store/dashboard-brand-store.component';
import { DashboardFeatureRecipesComponent } from 'src/yess-pages/yess-home/components/dashboard-feature-recipes/dashboard-feature-recipes.component';
import { DashbaordMainCatDoodleComponent } from 'src/yess-pages/yess-home/components/dashbaord-main-cat-doodle/dashbaord-main-cat-doodle.component';
import { MainSliderComponent } from 'src/yess-pages/yess-home/components/main-slider/main-slider.component';
import { DashboardSeasonsMustHaveComponent } from 'src/yess-pages/yess-home/components/dashboard-seasons-must-have/dashboard-seasons-must-have.component';
import { DashboardTestimonialComponent } from 'src/yess-pages/yess-home/components/dashboard-testimonial/dashboard-testimonial.component';
import { MyOrderComponent } from 'src/yess-pages/yes-account/pages/my-order/my-order.component';
import { AuthGuardService } from 'src/services/auth-gaurd-service/auth-guard.service';
import { CategoryFiltersSideBarComponent } from 'src/common-components/category-filters-side-bar/category-filters-side-bar.component';
import { YessNoPageFoundComponent } from 'src/yess-pages/yess-no-page-found/yess-no-page-found/yess-no-page-found.component';
import { ChangeStoreConfirmationComponent } from 'src/popups/change-store-confirmation/change-store-confirmation/change-store-confirmation.component';
import { DashboardBeautiAndCareOffersComponent } from 'src/yess-pages/yess-home/components/dashboard-beauti-and-care-offers/dashboard-beauti-and-care-offers.component';
import { MismatchDeliveryAndStoreAlertComponent } from 'src/popups/mismatch-delivery-and-store-alert/mismatch-delivery-and-store-alert.component';
import { AgmCoreModule } from '@agm/core';
import { confirmOrderPopupComponent } from 'src/popups/confirm-order-popup/confirm-order-popup.component';
import { YessPipeModule } from 'src/CommonModule/yess-pipe/yess-pipe.module';
import { YessOfferListComponent } from 'src/yess-pages/yess-home/yess-offer-list/yess-offer-list.component';
import { YessPayMsgComponent } from 'src/yess-pages/yess-pay-response/yess-pay-msg/yess-pay-msg.component';
import { OffersListsComponent } from 'src/yess-pages/yess-home/components/offers-lists/offers-lists.component';
import { ShowCouponCodeComponent } from 'src/popups/show-coupon-code/show-coupon-code.component';
import { ShowDeliverySlotComponent } from 'src/popups/show-delivery-slot/show-delivery-slot.component';
//import { FilterPipe } from 'src/shared/pipes/filter.pipe';
import { OfferFruitsVegComponent } from 'src/yess-pages/yess-home/components/offer-fruits-veg/offer-fruits-veg.component';

@NgModule({
  declarations: [
    YessAppComponent,
    YessLayoutComponent,
    YessHeaderComponent,
    YessFooterComponent,

    YessPayMsgComponent,

    YessDashboardComponent,
    YessCategoryComponent,
    YessProductDetailsComponent,
    YessOfferListComponent,

    CategoryProductCardComponent,
    HeaderCategoryDropdownComponent,
    SecondTopBarComponent,
    FirstTopBarComponent,
    YessAccountLayoutComponent,
    YessMyAccountPageComponent,
    //ProductDetailSimilorProductCardComponent,

    confirmOrderPopupComponent,
    SignInSignUpPopupComponent,
    FacebookGoogleLoginComponent,
    FacebookGoogleSignupComponent,
    AllCategoriesComponent,
    DasboardOffersComponent,
    DashboardBankOffersComponent,
    DashboardFruitAndVegStoreComponent,
    DashboardDrinkAndBaveragesComponent,
    DashboardCleanHouseHoldComponent,
    DashboardBrandStoreComponent,
    DashboardFeatureRecipesComponent,
    DashbaordMainCatDoodleComponent,
    MainSliderComponent,
    DashboardSeasonsMustHaveComponent,
    DashboardTestimonialComponent,
    MyOrderComponent,
    CategoryFiltersSideBarComponent,
    YessNoPageFoundComponent,
    ChangeStoreConfirmationComponent,
    DashboardBeautiAndCareOffersComponent,
    MismatchDeliveryAndStoreAlertComponent,
    OffersListsComponent,
    OfferFruitsVegComponent,
    MismatchDeliveryAndStoreAlertComponent,
    MismatchDeliveryAndStoreAlertComponent,
    ShowCouponCodeComponent,
    ShowDeliverySlotComponent

  ],
  entryComponents: [SignInSignUpPopupComponent,
    ChangeStoreConfirmationComponent,
    confirmOrderPopupComponent,
    ShowCouponCodeComponent,
    ShowDeliverySlotComponent,
    MismatchDeliveryAndStoreAlertComponent],
  imports: [
    BrowserModule,
    YessAppRoutingModule,
    HttpClientModule,
    NgbModule,
    ClickOutsideModule,
    //FormsModule,
    SharedModule,
    YessPipeModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 6000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton: true,
    }),
    CookieModule.forRoot(),
    //CarouselModule,
    RatingModule,
    NgSelectModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD90oAnPippOQcMkCbayJVNOcxFhC9OUOQ',
      libraries: ['places']
    })
  ],

  providers: [CookieModule, NgbActiveModal,Title,Meta ],
  bootstrap: [YessAppComponent],
  exports: [YessAppComponent]
})
export class YessAppModule { }
