import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-header-category-dropdown',
  templateUrl: './header-category-dropdown.component.html',
  styleUrls: ['./header-category-dropdown.component.css']
})
export class HeaderCategoryDropdownComponent implements OnInit {
  @Input() categories: any = [];
  @Input() isMobileViewMenu: boolean = false;
  @Output() mainCategoryProductCallBack = new EventEmitter();
  @Output() subCategoryProductCallback = new EventEmitter();
  isMainMenuOpen: boolean = false;
  activeSubCategory: number = -1;
  isclick: boolean = false;
  constructor(private _router: Router,) { }

  ngOnInit() {

  }
  openCloseCategoryMenu(isMainMenuOpen) {
    this.activeSubCategory = -1;
    this.isMainMenuOpen = (!isMainMenuOpen);
    this.isclick = true;
  }
  openSubMenu(index) {
    if (index == this.activeSubCategory) {
      this.activeSubCategory = -1;
      this.isclick = true;
    }
    else {
      this.activeSubCategory = index;
      this.isclick = false;
    }
  }
  onClickedOutside() {
    if (!this.isMobileViewMenu) {
      this.activeSubCategory = -1;
      this.isMainMenuOpen = false;
      this.isclick = true;
    }
  }
  openMainCategoryProducts(mainCat) {
    const localmain = Object.assign({}, mainCat);
    this.openCloseCategoryMenu(true);
    this.mainCategoryProductCallBack.emit(localmain);

  }
  openSubCategory1Products(maincat, subCat) {
    let localmain = Object.assign({}, maincat);
    localmain.SubCategory = subCat;
    this.openCloseCategoryMenu(true);
    this.subCategoryProductCallback.emit(localmain);
  }
  trackByCat(index: number, item) {
    return index;
  }
  trackBySubcat(index: number, item) {
    return index;
  }

  openOffer(offerNumber, offer) {
    this.isclick = true;
    switch (offerNumber) {
      case 1:
        let navigationExtras1: NavigationExtras = {
          queryParams: {
            "Type": 'Offer',
            "Data": JSON.stringify({
              "Id": offer.MenuOffer1Code,
              "Name": offer.CategoryName,
            }),
          }
        };
        //this._router.navigate(['offers'], navigationExtras1);
        this._router.navigate(['category/Offer/'+offer.CategoryName+'/'+offer.MenuOffer1Code]);
        break;
      case 2:
        let navigationExtras2: NavigationExtras = {
          queryParams: {
            "Type": 'Offer',
            "Data": JSON.stringify({
              "Id": offer.MenuOffer2Code,
              "Name": offer.CategoryName,
            }),
          }
        };
        //this._router.navigate(['offers'], navigationExtras2);
        this._router.navigate(['category/Offer/'+offer.CategoryName+'/'+offer.MenuOffer2Code]);
        break;

      default:
        this._router.navigate(['page-not-found']);
        break;

    }
  }
}
