import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private cartCount = new Subject();
  cartCount$ = this.cartCount.asObservable();

  private updateCart = new Subject();
  updateCart$ = this.updateCart.asObservable();

  private updateStore = new Subject();
  updateStore$ = this.updateStore.asObservable();

  constructor() { }

  updateCartCount() {
    this.cartCount.next();
  }

  updateCartPage() {
    this.updateCart.next();
  }

  updateSelectedStore(data) {
    this.updateStore.next(data);

  }

}
