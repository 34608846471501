import { Injectable } from '@angular/core';
import { CartService } from '../cart.service/cart.service';
@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  constructor(private _cartService: CartService) {

  }
  getCategoryPageData() {
    return JSON.parse(sessionStorage.getItem('yes_cat'));
  }
  setCategoryPageData(catData, callback) {
    sessionStorage.setItem('yes_cat', JSON.stringify(catData));
    if (callback) { callback() }
  }
  deleteSelectedOffer() {
    sessionStorage.removeItem('yes_cat');
  }
  clear() {
    sessionStorage.clear();
  }

  setCartItemData(catData, callback) {
    this._cartService.serviceItemList=catData;
    sessionStorage.setItem('yes_cat_p', JSON.stringify(catData));
    if (callback) { callback() }
  }
  getCartItemData() {
    return JSON.parse(sessionStorage.getItem('yes_cat_p'));
  }
  deleteCartItemData() {
    this._cartService.serviceItemList=[];
    this._cartService.servicePaymentDetails={};
    this._cartService.ItemListChange.next();
    this._cartService.paymentDetailsChange.next();
    sessionStorage.removeItem('yes_cat_p');
  }

}
