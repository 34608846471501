import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-yess-account-layout',
  templateUrl: './yess-account-layout.component.html',
  styleUrls: ['./yess-account-layout.component.css']
})
export class YessAccountLayoutComponent implements OnInit {

  constructor(public _router: Router) { }

  ngOnInit() {

  }
  goToHome() {
    this._router.navigate(['/home'])
  }
}
