import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment'
import { HttpService } from '../http.service/httpService';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  serverURL = environment.baseUrl;
  constructor(private _httpService: HttpService) { }
  loadBanner(BannerType, StoreId) {
    return this._httpService.get("api/banner/GetBanners?BannerType=" + BannerType + "&StoreId=" + StoreId);
  }
  getAllCategories(businessId) {
    return this._httpService.get("api/home/LoadMainCategoryList?Id=" + businessId);
  }
  getProductsByCategories(data: any) {
    return this._httpService.postGetCart("api/Home/GetProducts", data);
  }
  getBankOffers(businessId) {
    return this._httpService.get("api/home/bankoffer?id=" + businessId);
  }
  loadhomecategories(data: any) {
    return this._httpService.postGetCart("api/home/loadhomecategories", data);
  }
  getAllHomePageBrands() {
    return this._httpService.get("api/Home/HomePageBrands");
  }
  getCleaningAndHouseHold(businessId) {
    return this._httpService.get("api/Home/getCleaningAndHouseHold?Id=" + businessId);
  }
  getAllBrandsStore(businessId) {
    return this._httpService.post("api/Home/LoadAllBrands/" + businessId);
  }
  getFeatureRecipe(businessId) {
    return this._httpService.post("api/Home/LoadFeaturedRecipes/" + businessId);
  }
  getMainCategoriesDoodles(businessId) {
    return this._httpService.get("api/Home/LoadMainCategorylist?id=" + businessId);
  }
  getAllTestimonialList(businessId) {
    return this._httpService.post("api/home/Testimonials?StoreId=" + businessId);

  }
  getAllSeasonProduct(businessId) {
    return this._httpService.get("api/home/LoadSeason?Id=" + businessId);
  }
  getAllHomePageOffers(storeId) {
    return this._httpService.get("api/Offer/GetHomePageOffers?StoreId=" + storeId);
  }
  getSiteSettingDetails() {
    return this._httpService.get("api/home/GetSiteSettingDetails");
  }
  saveContactUsMessage(data: any) {
    return this._httpService.postGetCart("api/Home/SaveContactUsMessage",data);
  }
}
