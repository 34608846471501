import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment'
import { HttpService } from '../http.service/httpService';

@Injectable({
  providedIn: 'root'
})
export class SignUpLoginService {
  serverURL = environment.baseUrl;
  constructor(private _httpService: HttpService) { }
  signUpByNumber(mobileNumber: number) {
    return this._httpService.get("api/Login/signupotp?Phone=" + mobileNumber);
  }
  saveFacebookSignUp(facebookModel) {
    return this._httpService.postGetCart("api/Login/saveFacebookSignUp",facebookModel);
  }
  saveGoogleSignUp(googleModel) {
    return this._httpService.postGetCart("api/Login/saveGoogleSignUp",googleModel);
  }
  signInByNumberOrEmail(data: any) {
    return this._httpService.postGetCart("api/Login/GenerateOTP", data);
  }
  ValidateLoginOTP(data) {
    const jsonData = JSON.stringify(data);
    return this._httpService.postGetCart("api/Login/ValidateOTP", jsonData);
  }
  validateSignUpOTP(phone: number, OTP: number) {
    return this._httpService.get("api/Login/validatesignupotp?Phone=" + phone + "&otp=" + OTP);
  }
  // saveSigupExtraInformation(data: any) {
  //   return this._httpService.postGetCart("api/Login/saveInformation", data);
  // }
 
}

