import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { LayoutService } from 'src/services/layout.service/layout.service';
import { CityListModel, AreaListModel } from 'src/models/layout-models/layout.models';
import { Subscription } from 'rxjs';
import { CookieStorageService } from 'src/services/cookie-service/cookie.service';
import { DOCUMENT } from '@angular/common';
import { SharedService } from 'src/services/shared-service/shared.service';
import { ChangeStoreConfirmationComponent } from 'src/popups/change-store-confirmation/change-store-confirmation/change-store-confirmation.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-first-top-bar',
  templateUrl: './first-top-bar.component.html',
  styleUrls: ['./first-top-bar.component.css']
})
export class FirstTopBarComponent implements OnInit {
  @ViewChild('pincodecontrol', { static: true }) pincodeControl: ElementRef;
  @ViewChild('selectCityZip', { static: true }) selectCityZip: ElementRef;



  localSelectedCity: string = "";
  selectedCityAndPin: any = {};
  isLoadinAreaLoaderShow: boolean = false;
  cityList: CityListModel[] = [];
  searchText: string = "";
  searchAreaServiceSubscription: Subscription;
  areaList: AreaListModel[] = [];
  constructor(private _layoutService: LayoutService,
    private _cookieService: CookieStorageService,
    @Inject(DOCUMENT) private document: Document,
    private modalService: NgbModal, ) {
    const storeAddress = this._cookieService.getCityAndPin();
    if (storeAddress && storeAddress.City && storeAddress.Pin) {
      this.selectedCityAndPin = {
        City: storeAddress.City,
        Pin: storeAddress.Pin,
      }
    }
  }

  ngOnInit() {
    // this.openConfirmationPopup();
    this.getAllCities();
  }
  openConfirmationPopup(area, localSelectedCity) {
    this.selectCityZip.nativeElement.click();
    const modalRef = this.modalService.open(ChangeStoreConfirmationComponent,
      { centered: true, backdrop: 'static', keyboard: false, size: 'lg', windowClass: "" }
    );
    modalRef.result.then((data) => {
      if (area && area.StoreId) {
        this.selectedCityAndPin = {
          City: area.CityName,//localSelectedCity,
          Pin: area.Pincode,
        }
        this._cookieService.setCityAndPin(this.selectedCityAndPin);
        this._cookieService.setStoreId(area.StoreId);
        this._cookieService.setStoreInfo(area);
        this.document.location.reload();
      }
    }, (reason) => {
      this.resetValues();
    });
  }

  resetValues() {
    const storeAddress = this._cookieService.getCityAndPin();
    if (storeAddress && storeAddress.City && storeAddress.Pin) {
      this.selectedCityAndPin = {
        City: storeAddress.City,
        Pin: storeAddress.Pin,
      }
      this.localSelectedCity = this.selectedCityAndPin.City;
    }
    this.searchText = "";
    this.areaList = [];
  }

  getAllCities() {
    this._layoutService.loadAllCity().subscribe(res => {
      let result: any = res;
      if (!result.IsError) {
        this.openSelectCityPopup();
        this.cityList = result.ResponseMessage;
        this.localSelectedCity = this.selectedCityAndPin.City;
      }
    })
  }
  openSelectCityPopup() {
    const isFirstTime: any = this._cookieService.getFirstTimeIndicator();
    if (isFirstTime === undefined) {
      this.selectCityZip.nativeElement.click();
      this._cookieService.setFirstTimeIndicator();
    }

  }
  searchAddress() {
    this.searchAddressAPICall(this.searchText, this.localSelectedCity);
    //this.searchAddressAPICall(this.searchText, "");
  }
  selectCity(selectedCity) {
    this.pincodeControl.nativeElement.focus();
    this.localSelectedCity = selectedCity;
  }
  searchAddressAPICall(searchText, selectedCity) {
    if (this.searchAreaServiceSubscription) {
      this.searchAreaServiceSubscription.unsubscribe();
    }
    this.isLoadinAreaLoaderShow = true;
    this.searchAreaServiceSubscription = this._layoutService.LoadArea(searchText, selectedCity)
    .subscribe(res => {
      let result: any = res;
      this.isLoadinAreaLoaderShow = false;
      if (!result.IsError) {
        if (!result.ResponseMessage) {
          this.areaList = [];
        } 
        else {
          this.areaList = result.ResponseMessage;
        }
      } 
      
    }, err => {
      this.isLoadinAreaLoaderShow = false;
    })
  }
  selectArea(area) {
    this.localSelectedCity = area.CityName
    this.openConfirmationPopup(area, this.localSelectedCity);
  }
}
