import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { cartProductModel } from 'src/models/cart-models/cart.models';

@Injectable({
  providedIn: 'root'
})
export class CartSharedService {

  ItemList: cartProductModel[] = [];
  paymentDetails: any = {};
  private updateAmount = new Subject();
  updateAmount$ = this.updateAmount.asObservable();
  constructor() { }

  updateTotalAmount() {
    this.updateAmount.next();
  }

}
