import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NoWhiteSpaceFirst, DigitOnly, CharacterOnly, Alphanumeric, NoWhiteSpace, BlockCopyPasteDirective } from 'src/shared/directives/directives';
import { AgmCoreModule } from '@agm/core';
import { ProductDetailSimilorProductCardComponent } from 'src/common-components/product-detail-similor-product-card/product-detail-similor-product-card.component';
import { FormsModule } from '@angular/forms';
import { CarouselModule } from 'ngx-owl-carousel-o';

@NgModule({
  imports: [
    CommonModule, 
    FormsModule,
    CarouselModule,
  ],
  declarations: [
    NoWhiteSpaceFirst,
    DigitOnly,
    CharacterOnly,
    Alphanumeric,
    NoWhiteSpace,
    BlockCopyPasteDirective,
    ProductDetailSimilorProductCardComponent,   
  ],
  exports: [
    NoWhiteSpaceFirst,
    NoWhiteSpace,
    DigitOnly,
    CharacterOnly,
    Alphanumeric,
    BlockCopyPasteDirective,   
    ProductDetailSimilorProductCardComponent,
    CarouselModule,
    FormsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [],
  providers: [DatePipe],

})
export class SharedModule { }
