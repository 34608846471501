import { Component } from '@angular/core';
import * as uuid from 'uuid';
import { CookieStorageService } from 'src/services/cookie-service/cookie.service';
import { HostListener } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'yess-app-root',
  templateUrl: './yess-app.component.html',
  styleUrls: ['./yess-app.component.css']
})
export class YessAppComponent {
  constructor(private _cookieService: CookieStorageService,
    private router: Router,  
    private activatedRoute: ActivatedRoute,  
    private titleService: Title,
    private metaDescService: Meta ) {

  }
  ngOnInit() {
    const ssid: any = this._cookieService.getSessionId();
    if (ssid === undefined) {
      const sessionId = uuid.v4();
      this._cookieService.setSessionId(sessionId);
    }
    
    this.router.events.pipe(  
      filter(event => event instanceof NavigationEnd),  
    ).subscribe(() => {  
      const rt = this.getChild(this.activatedRoute); 
      rt.url.subscribe(data => { 
        if(data[0].path=="category"){
        this.titleService.setTitle(data[2].path);
        this.metaDescService.updateTag({ name: 'description', content: data[2].path });
        }
        else{
          rt.data.subscribe(data => {
              this.titleService.setTitle(data.title);
              this.metaDescService.updateTag({ name: 'description', content: data.metaDesc });
            });  
        }
      }); 
      // rt.data.subscribe(data => {  
      //   console.log(data);  
      //   this.titleService.setTitle(data.title);
      // });  
    });  
  }
  getChild(activatedRoute: ActivatedRoute) {  
    if (activatedRoute.firstChild) {  
      return this.getChild(activatedRoute.firstChild);  
    } else {  
      return activatedRoute;  
    }  
  
  }  
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    setTimeout(() => {
      window.scroll(0, 0);
    }, 500);
 
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
  }
}
