import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FacebookModel, GoogleModel } from 'src/models/signup-login-models/signup.login.models';
import { CookieStorageService } from 'src/services/cookie-service/cookie.service';
import { DashboardService } from 'src/services/dashboard.service/dashboard.service';
import { SignUpLoginService } from 'src/services/signup-login.service/signup.login.service';
import { YessConstants } from 'src/shared/constants';
@Component({
  selector: 'app-facebook-google-signup',
  templateUrl: './facebook-google-signup.component.html',
  styleUrls: ['./facebook-google-signup.component.css']
})
export class FacebookGoogleSignupComponent implements OnInit {
  @ViewChild('loginRef', { static: true }) loginElement: ElementRef;
  auth2: any;
  FacebookAppId:string;
  GoogleClientId:string;
  constructor(private _signUpLoginService: SignUpLoginService,    
    private _homeService: DashboardService,    
    private toastr: ToastrService,
    private _cookieStorageService: CookieStorageService) {
      this.setFbGoogleAppKey();
    }

  ngOnInit() {
    //this.setFbGoogleAppKey();
    // this.googleSDK();
    // this.fbLibrary();
  }
  setFbGoogleAppKey(){    
    this._homeService.getSiteSettingDetails().subscribe(res => {
      let result: any = res;
      if (!result.IsError) {
        this.FacebookAppId =result.ResponseMessage.FacebookLoginAppId;
        this.GoogleClientId =result.ResponseMessage.GoogleLoginAccId;
        this.googleSDK();
        this.fbLibrary();
      } else {
        this.toastr.error(result.ErrorMessage);
      }
    });
  }
  googleSDK() {
    window['googleSDKLoaded'] = () => {
      window['gapi'].load('auth2', () => {
        this.auth2 = window['gapi'].auth2.init({
          client_id: this.GoogleClientId,
          //client_id: YessConstants.ProjectKeys.GoogleClientId,
          cookiepolicy: 'single_host_origin',
          scope: 'profile email'
        });
        this.prepareLoginButton();
      });
    }

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'google-jssdk'));

  }
  prepareLoginButton() {
    this.auth2.attachClickHandler(this.loginElement.nativeElement, {},
      (googleUser) => {
        let profile = googleUser.getBasicProfile();
        const googleModel: GoogleModel = {
          Token: googleUser.getAuthResponse().id_token,
          Id: profile.getId(),
          Name: profile.getName(),
          ImageURL: profile.getImageUrl(),
          Email: profile.getEmail()
        }
        this.saveGoogleSignUp(googleModel);
      }, (error) => {
        //this.toastr.error(JSON.stringify(error, undefined, 2));
      });

  }

  fbLibrary() {

    (window as any).fbAsyncInit = function () {
      window['FB'].init({
        //appId: this.FacebookAppId,
        appId: YessConstants.ProjectKeys.FacebookAppId,
        cookie: true,
        xfbml: true,
        version: 'v3.1'
      });
      window['FB'].AppEvents.logPageView();
    };

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

  }
  facebooklogin() {
    window['FB'].login((response) => {
      console.log('login response', response);
      if (response.authResponse) {
        const authenticationresponse = response.authResponse;
        window['FB'].api('/me', {
          fields: 'last_name, first_name, email',
        }, (userInfo) => {
          debugger
          //var d=new Date(authenticationresponse.data_access_expiration_time.match(/\d+/)[0] * 1)
          const facebookModel: FacebookModel = {
            Email: userInfo.email,
            FirstName: userInfo.first_name,
            Id: userInfo.id,
            LastName: userInfo.last_name,
            AccessToken: authenticationresponse.accessToken,
            //DataAccessExpirationTime: authenticationresponse.data_access_expiration_time,//new Date(parseInt((authenticationresponse.data_access_expiration_time).substr(6))),
            DataAccessExpirationTime:null,
            ExpiresIn: authenticationresponse.expiresIn+'',
            GraphDomain: authenticationresponse.graphDomain,
            SignedRequest: authenticationresponse.signedRequest,
          }
          this.saveFacebookSignUp(facebookModel);
        });

      } else {
        console.log('User login failed');
      }
    }, { scope: 'email' });
  }

  saveFacebookSignUp(facebookModel: FacebookModel) {
    debugger
    console.log(facebookModel);
    this._signUpLoginService.saveFacebookSignUp(facebookModel).subscribe(res => {
      let result: any = res;
      debugger
      if (!result.IsError) {
        this._cookieStorageService.setUser(result.buyer);
        location.reload();
      } else {
        this.toastr.error(result.ErrorMessage);
      }
    });
  }
  saveGoogleSignUp(googleModel: GoogleModel) {
    this._signUpLoginService.saveGoogleSignUp(googleModel).subscribe(res => {
      let result: any = res;
      debugger
      if (!result.IsError) {
        this._cookieStorageService.setUser(result.buyer);
        location.reload();
      } else {
        this.toastr.error(result.ErrorMessage);
      }
    });

  }
}
