import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DashboardService } from 'src/services/dashboard.service/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/services/common-services/common.service';
import { OwlOptions } from 'ngx-owl-carousel-o';


@Component({
  selector: 'app-dasboard-offers',
  templateUrl: './dasboard-offers.component.html',
  styleUrls: ['./dasboard-offers.component.css']
})
export class DasboardOffersComponent implements OnInit {
  offerData: any = [];
  displayType: number = 1;
  customOptions: OwlOptions;
  @Output() addToCartCallBack = new EventEmitter();
  @Input() offerSection: any = {};
  @Output() openOfferPageCallBack = new EventEmitter();
  @Output() showProductDetailsCallBack = new EventEmitter();

  constructor(private _dashboardService: DashboardService,
    private toastr: ToastrService,
    public _commonService: CommonService) {
    this.displayType = 2;
    this.customOptions = {
      loop: false,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: true,
      navSpeed: 700,
      //autoplay: true,
      navText: ['', ''],
      responsive: {
        0:
        {
          items: 1
        },
        575:
        {
          items: 2
        }, 840:
        {
          items: 4
        }, 1000:
        {
          items: 6
        },
        1440:
        {
          items: 8
        }
      },
      nav: true
    }
  }

  ngOnInit() {
  }

  addToCart(product) {
    this.addToCartCallBack.emit(product)
  }

  openOfferPage(offer) {debugger
    this.openOfferPageCallBack.emit(offer);
  }

  showDetail(product) {
    this.showProductDetailsCallBack.emit(product);
  }
  openOffer(offer) {
    this.openOfferPageCallBack.emit(offer);
  }
  openProductDetails(product) {
    this.showProductDetailsCallBack.emit(product);
  }
}

