import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductDetailService } from 'src/services/product.detail.service/product.detail.service';
import { CategoryFilter, AddToCartModel } from 'src/models/category-models/category.models';
import { CategoryService } from 'src/services/category.service/category.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CookieStorageService } from 'src/services/cookie-service/cookie.service';
import { SharedService } from 'src/services/shared-service/shared.service';
import { ToastrService } from 'ngx-toastr';
import { ProductDetailModel, Images } from 'src/models/product-detail-models/product.detail.models';
import { YessConstants } from 'src/shared/constants';
import { CommonService } from 'src/services/common-services/common.service';

@Component({
  selector: 'yess-product-details',
  templateUrl: './yess-product-details.component.html',
  styleUrls: ['./yess-product-details.component.css']
})
export class YessProductDetailsComponent implements OnInit {
  ProductImages: any = [];
  parameterServiceSubscription: Subscription;
  productDetail = {} as ProductDetailModel;
  similorProducsFilter = {} as CategoryFilter;
  businessId: number = 0;
  similorProductList: any = [];
  customOptions: OwlOptions;
  addToCartServiceSubscription: Subscription;
  buyerId: number = 0;
  ItemCode:number =0;
  constructor(private _activatedroute: ActivatedRoute,
    private _productDetailService: ProductDetailService,
    private _categoryService: CategoryService,
    private _cookieStorageService: CookieStorageService,
    private _sharedService: SharedService,
    private toastr: ToastrService,
    public _commonService: CommonService ,
    private _router: Router,) {
    this.buyerId = this._cookieStorageService.getBuyerId();
    this.businessId = +this._cookieStorageService.getStoreId();

    this.customOptions = {
      loop: false,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: false,
      dots: false,
      navSpeed: 700,
      smartSpeed: 700,
      //navText: ['Back', 'Next'],
      navText: ['', ''],
      responsive: {
        0: {
          items: 1
        },
        575: {
          items: 3
        }, 840: {
          items: 4
        }, 1000: {
          items: 5
        },
        1440: {
          items: 7
        }
      },
      nav: true
    }
    this.buyerId = this._cookieStorageService.getBuyerId();
  }
  resetSimilorProductFilter() {
    this.similorProducsFilter = {
      StoreId: this.businessId,
      CategoryId: 0,
      SubCategoryId: 0,
      Offerid: 0,
      Brands: "",
      Manufacturers: "",
      PageSize: YessConstants.PageSize,
      PageNo: 1,
      Orderby: "Price",
      OrderDirection: "ASC",
      Priceranges: "",
    }
  }
  ngOnInit() {
    if (this.parameterServiceSubscription) {
      this.parameterServiceSubscription.unsubscribe();
    }
    this.parameterServiceSubscription = this._activatedroute.paramMap.subscribe((params: any) => {
      const productId = params["params"].id;
      this.ItemCode= params["params"].id;
      const catId = params["params"].catId;
      this.getProductDetail(productId);
      this.resetSimilorProductFilter();
      this.similorProducsFilter.CategoryId = catId;
      this.getSimilorProducts(this.similorProducsFilter);

      //this.productDetail = {
      //  "ItemImages": [
      //    { ImagePath: 'assets/images/p_img_big.jpg', Id: 1 },
      //    { ImagePath: 'assets/images/p_img_big2.jpg', Id: 1 },
      //    { ImagePath: 'assets/images/p_img_big.jpg', Id: 1 },
      //    { ImagePath: 'assets/images/p_img_big2.jpg', Id: 1 },
      //  ],
      //  "Name": "Aashirvaad Select Sharbati Whole Wheat Atta",
      //  "Id": 7686,
      //  "Description": "Aashirvaad Select Sharbati Whole Wheat Atta is ground with utmost precision, using the traditional chakki-grinding process and the choicest of grains to give you fresh and 100% pure wheat flour. Enriched with wholesome vitamins, it can be used to prepare the softest of rotis. The use of superior quality grains makes Aashirvaad Whole Wheat Atta a healthy and wholesome choice. Makes soft and melt-in-the-mouth rotis. Pure wheat grain, without any maida addition, is used in the preparation of the flour. Provides fibre content to the body. Contains nutrients that keep you healthy and fit.",
      //  "BrandName": null,
      //  "U_MRP": 158.0,
      //  "U_CSP": 79.0,
      //  "KeyFeature": [
      //    { "Feature": 'Made from the choicest whole wheat' },
      //    { "Feature": 'Fresh and pure wholesome flour' },
      //    { "Feature": 'Grounded carefully to perfection' },
      //    { "Feature": 'Rich in essential vitamins and extra fibre' },
      //    { "Feature": 'Makes soft and fluffy rotis with a whole lot of health' },
      //    { "Feature": 'Can also be used to make a variety of other sweets and savouries' },
      //  ],
      //  'Ingredients': "Whole Wheat",
      //  'Weight': '5',
      //  'Unit_Of_Measure': 'kg',
      //  'Expiry': '3 months',
      //  'Manufacturer': 'ITC Limited, 37, J.L. Nehru Road, Kolkata- 700071',
      //  'Seller': 'ABC Super Retail Pvt Ltd',
      //  'SKU': null,

      //}
    });
  }
  getSimilorProducts(filter: CategoryFilter) {
    this._categoryService.getAllProducts(filter).subscribe(res => {
      let result: any = res;
      if (!result.IsError) {
        debugger
        const list: [] = result.ResponseMessage && result.ResponseMessage.Products ? result.ResponseMessage.Products : []
        this.similorProductList = list;
        this.similorProductList= this.similorProductList.filter((a,i,arr)=>a.ItemCode != this.ItemCode);
          this.similorProductList.every(res => {
            res.Quantity = 1;
            return true;
          });
      } else {
        this.similorProductList = [];
      }
    }, err => {
      this.similorProductList = [];
    })
  }
  getProductDetail(productId: any) {
    this._productDetailService.getProductDetailById(productId, this.businessId).subscribe(res => {
      let result: any = res;
      if (result.IsError) {
        this.toastr.error(result.ErrorMessage);
      } else {
        this.productDetail = result.ResponseMessage.sp_GetItemDetailsByCodeList1s[0];
        this.ProductImages = result.ResponseMessage.sp__getItemImageByItemCodes ? result.ResponseMessage.sp__getItemImageByItemCodes : [];
        if (this.ProductImages && this.ProductImages.length === 0) {
          const url = "assets/images/dev_images/noImageAvailable.jpg"
          let obj: any = {
            "ItemCode": "",
            "FileName": "",
            "ItemPhoto": "assets/images/dev_images/noImageAvailable.jpg"
          };
          this.ProductImages.push(obj);
        }
        this.productDetail.Quantity = 1;
      }
    }, err => {
      this.toastr.error("Something went wrong");
    })
  }

  manualQty(product: ProductDetailModel) {
    if (product.Quantity <= 999) {
      product.oneStepBackOldValue = product.Quantity;
      return true;
    } else {
      product.Quantity = product.oneStepBackOldValue;
    }

  }
  addToCart() {
    this.similorProductAddToCart(this.productDetail)
  }
  similorProductAddToCart(productToAdd: any) {

    if (productToAdd && productToAdd.Quantity) {
      const ssid: any = this._cookieStorageService.getSessionId();
      const product: AddToCartModel = {
        "StoreId": this.businessId,
        "ProductId": productToAdd.ProductId,
        "SessionId": ssid,
        "SKU": productToAdd.SKU ? productToAdd.SKU : "",
        "Qty": productToAdd.Quantity,
        "BuyerId": this.buyerId ? this.buyerId : 0,
      }
      this.addToCartAPICall(product);
    } else {
      this.toastr.error("Enter a valid Quantity");
    }

  }
  addToCartAPICall(addProduct: AddToCartModel) {
    if (this.addToCartServiceSubscription) {
      this.addToCartServiceSubscription.unsubscribe();
    }
    this.addToCartServiceSubscription = this._categoryService.addToCart(addProduct).subscribe(res => {
      let result: any = res;
      if (!result.IsError) {
        //this.toastr.success(result.ResponseMessage); 
        this.toastr.success("Product successfully added to cart ");
        this._sharedService.updateCartCount();
      } else {
        this.toastr.error(result.ErrorMessage);
      }
    }, err => {
      this.toastr.error("Something went wrong");
    })
  }
  openDetailPage(product: any) {
    const url = "/product-detail/" + product.ItemCode + "/" + product.CategoryId;
    this._router.navigate([url]);

  }
}
