import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { ProductModel } from 'src/models/category-models/category.models';
import { CommonService } from 'src/services/common-services/common.service';

@Component({
  selector: 'app-product-detail-similor-product-card',
  templateUrl: './product-detail-similor-product-card.component.html',
  styleUrls: ['./product-detail-similor-product-card.component.css']
})
export class ProductDetailSimilorProductCardComponent implements OnInit {
  @Input() product = {} as ProductModel;
  @Output() ImageClickCallBack = new EventEmitter();
  @Output() addToCartCallBack = new EventEmitter();
  oldValue: number = 0;
  constructor(
    public _commonService: CommonService) { }

  ngOnInit() {
    this.product.Quantity = 1;
  }
  openProductDetailPage() {
    this.ImageClickCallBack.emit(this.product);
  }
  addToCart() {
    this.addToCartCallBack.emit(this.product);
  }
  manualQty(product: ProductModel) {
    if (product.Quantity <= 999) {
      this.oldValue = product.Quantity;
      return true;
    } else {
      product.Quantity = this.oldValue;
    }

  }

}
