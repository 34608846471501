import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DashboardService } from 'src/services/dashboard.service/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CategoryFilter } from 'src/models/category-models/category.models';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CommonService } from 'src/services/common-services/common.service';

@Component({
  selector: 'app-all-categories',
  templateUrl: './all-categories.component.html',
  styleUrls: ['./all-categories.component.css']
})
export class AllCategoriesComponent implements OnInit {
  @Input() businessId: number = 0;
  @Output() addToCartCallBack = new EventEmitter();
  @Output() imageClickCallBack = new EventEmitter();
  customOptions: OwlOptions;
  selectedCatIndex: number = 0;
  allCategoriesList: any = [];
  ProductsList: any = [];
  categoryFilter: any = {};
  loadCategoryProductsAPICallServiceSubscription: Subscription;
  constructor(private _dashboardService: DashboardService,
    private toastr: ToastrService,
    public _commonService: CommonService ) { 
      this.customOptions = {
        loop: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        navSpeed: 700,
        autoplay: true,
        navText: ['', ''],
        responsive: {
          0: {
            items: 1
          },
          300: {
            items: 2
          },
          600: {
            items: 3
          },
          700: {
            items: 4
          },
          940: {
            items: 7
          }
        },
        nav: true
      }
    }

  ngOnInit() {
    this.getAllCategoris(this.businessId);
    this.resetFilter();
  }
  resetFilter() {
    this.categoryFilter = {
      StoreId: +this.businessId,
      CategoryId: 0,
      SubCategoryId: 0,
      Brands: "",
      Manufacturers: "",
      PageSize: 12,
      PageNo: 1,
      Orderby: "Price",
      OrderDirection: "ASC",
      Priceranges: "",
      Offerid: 0,
      OfferCode: "",
      IsRefreshList: false,

    }
  }
  getAllCategoris(businessId) {
    this._dashboardService.getAllCategories(businessId).subscribe(res => {
      let result: any = res;
      if (!result.IsError) {
        this.allCategoriesList = result.ResponseMessage;
        this.categoryFilter.CategoryId = this.allCategoriesList[0].CategoryId
        //this.loadCategoryProducts(this.allCategoriesList[0], 1)
      }
    }, err => {
      this.toastr.error("Something went wrong while getting category");
    })
  }
  loadCategoryProductsAPICall(categoryFilter: CategoryFilter) {
    if (this.loadCategoryProductsAPICallServiceSubscription) {
      this.loadCategoryProductsAPICallServiceSubscription.unsubscribe();
    }
    this.loadCategoryProductsAPICallServiceSubscription = this._dashboardService.getProductsByCategories(categoryFilter).subscribe(res => {
      let result: any = res;
      if (result.IsError) {
        this.ProductsList = this.addDummyObjects(this.ProductsList, 12);
      } else {
        this.ProductsList = result.ResponseMessage && result.ResponseMessage.Products ? result.ResponseMessage.Products : [];
        if (this.ProductsList.length < 12) {
          const length = 12 - this.ProductsList.length;
          this.ProductsList = this.addDummyObjects(this.ProductsList, length);
        }
      }
      this.ProductsList.every(a=>{ a.Quantity=1 ;return true;});
    }, err => {
      this.toastr.error("Something went wrong while getting category products");
    });

  }
  loadCategoryProducts(category, index) {
    if (this.selectedCatIndex === index) {
      this.selectedCatIndex = null;
      this.categoryFilter.CategoryId = category.CategoryId
      this.ProductsList = [];
      return;
    }
    this.selectedCatIndex = index;
    this.categoryFilter.CategoryId = category.CategoryId
    this.ProductsList = [];
    this.loadCategoryProductsAPICall(this.categoryFilter);
  }


  addToCart(product) {
    this.addToCartCallBack.emit(product);
  }
  addDummyObjects(list: any = [], doodleWantToAdd: number) {
    for (var i = 1; i <= doodleWantToAdd; i++) {
      const obj = {
        ItemPhoto: "assets/images/dev_images/noImageAvailable.jpg",
        ProductId: 0,
        ProductNAME: "Not Available",
        U_MRP: null,
      }
      list.push(obj);
    }
    return list;
  }
  imageClick(product) {
    this.imageClickCallBack.emit(product);
  }

}
