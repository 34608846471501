import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CartService } from 'src/services/cart.service/cart.service';
import { Input } from '@angular/core';
import { CookieStorageService } from 'src/services/cookie-service/cookie.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-show-coupon-code',
  templateUrl: './show-coupon-code.component.html',
  styleUrls: ['./show-coupon-code.component.css']
})
export class ShowCouponCodeComponent implements OnInit {
  OfferCouponList: any;
  @Input() paymentDetail: {};
  constructor(private activeModal: NgbActiveModal,
    private _cartService: CartService,
    private _cookieStorageService: CookieStorageService,
    private toastr: ToastrService
  ) {

  }
  ngOnInit() {
    this.getAllShowOfferList();
  }
  ApplyCoupon(data: any) {
    let UserId = this._cookieStorageService.getBuyerId();
    let CouponId = data.Id;
    this._cartService.CouponValidater(UserId, CouponId).subscribe(res => {
      let result: any = res;
      debugger;
        if (result==true) {
          this.activeModal.close(data);
        }
        else {
          this.toastr.error("Invalid Coupon");
        }
    })
  }
  closePopUp() {
    this.activeModal.dismiss();
  }
  getAllShowOfferList() {
    this._cartService.ShowOfferList().subscribe(res => {
      var result: any = res;
      this.OfferCouponList = result.ResponseMessage;
    })
  }

}
