import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DashboardService } from 'src/services/dashboard.service/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/services/common-services/common.service';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-dashboard-fruit-and-veg-store',
  templateUrl: './dashboard-fruit-and-veg-store.component.html',
  styleUrls: ['./dashboard-fruit-and-veg-store.component.css']
})
export class DashboardFruitAndVegStoreComponent implements OnInit {
  @Input() offerSection: any = {};
  @Output() addToCartCallBack = new EventEmitter();
  @Output() VegOfferCallBack = new EventEmitter();
  @Output() showProductDetailsCallBack = new EventEmitter();
  customOptions: OwlOptions;
  FuitsAndVegs: any = [];
  displayType: number=2;
  constructor(public _commonService: CommonService) {
    this.displayType=2;
    this.customOptions = {
      loop: false,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: true,
      navSpeed: 700,
      //autoplay: true,
      navText: ['', ''],
      responsive: {
        0:
        {
          items: 1
        },
        575:
        {
          items: 2
        }, 840:
        {
          items: 4
        }, 1000:
        {
          items: 6
        },
        1440:
        {
          items: 8
        }
      },
      nav: true
    }
   }

  ngOnInit() {
    console.log(this.offerSection)
  }

  addToCart(product) {
    this.addToCartCallBack.emit(product);
  }
  openOffer(offer) {
    this.VegOfferCallBack.emit(offer);
  }
  showDetail(product) {
    this.showProductDetailsCallBack.emit(product);
  }
  
  openOfferPage(offer) {
    this.VegOfferCallBack.emit(offer);
  }
  openProductDetails(product) {
    this.showProductDetailsCallBack.emit(product);
  }
}
