import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DashboardService } from 'src/services/dashboard.service/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-dashboard-feature-recipes',
  templateUrl: './dashboard-feature-recipes.component.html',
  styleUrls: ['./dashboard-feature-recipes.component.css']
})
export class DashboardFeatureRecipesComponent implements OnInit {
  @Input() businessId: number = 0;
  @Output() featuredRecipeClickCallBack = new EventEmitter();
  featureRecipe: any = [];
  customOptions: OwlOptions;
  constructor(private _dashboardService: DashboardService,
    private toastr: ToastrService) {
    this.customOptions = {
      loop: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: false,
      dots: false,
      autoplay: true,
      navSpeed: 700,
      smartSpeed: 700,
      //navText: ['Back', 'Next'],
      navText: ['', ''],
      responsive: {
        0: {
          items: 1
        },
      },
      nav: true
    }

  }

  ngOnInit() {
    this.getfeatureRecipe(this.businessId);
  }
  getfeatureRecipe(businessId) {
    this._dashboardService.loadBanner('BLOG', businessId).subscribe(res => {
      let result: any = res;
      if (result.IsError) {
        this.toastr.error(result.ErrorMessage);
        this.featureRecipe = [];
      } else {
        this.featureRecipe = result.ResponseMessage;
      }

    })


  }
  imageClick(reciepe) {
    this.featuredRecipeClickCallBack.emit(reciepe);
  }
}
