import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CartService } from 'src/services/cart.service/cart.service'
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-show-delivery-slot',
  templateUrl: './show-delivery-slot.component.html',
  styleUrls: ['./show-delivery-slot.component.css']
})
export class ShowDeliverySlotComponent implements OnInit {
  DeliverySlotList:any;
  selectedDay='';
  selectTime='';

  constructor(private activeModal: NgbActiveModal,private cartServiceApi:CartService,
    public datePipe: DatePipe) { 
    this.deliveryDate();
  }
  day=[];
  deliveryDate(){
   var todayDate = new Date();
   for(var i=0; i<7; i++){
    if(i===0){
      this.day.push//(todayDate.toDateString());
        ({
        'day':todayDate.getDay(),
        'date':todayDate.toDateString()
      })
    }
    else if(i===1){
      todayDate=new Date(todayDate.setDate(todayDate.getDate() + 1));
      this.day.push//((new Date(todayDate.setDate(todayDate.getDate() + 1))).toDateString());
      ({
        'day':todayDate.getDay(),
        'date':todayDate.toDateString()
      });
    }
    else{
      todayDate=new Date(todayDate.setDate(todayDate.getDate() + 1));
      this.day.push//((new Date(todayDate.setDate(todayDate.getDate() + 1))).toDateString());
      ({
        'day':todayDate.getDay(),
        'date':todayDate.toDateString()
      });
    }
   }
    
  }
 
  ngOnInit() {
    this.getAllShowDeliveryList();
  }
  ApplyDelivery(date,Start_Time,End_Time){
    var data={"date":this.selectedDay,
    "StartTime":Start_Time,
    "EndTime":End_Time }
    this.activeModal.close(data);
  }
  closePopUp(){
    this.activeModal.dismiss();
  } 
  getAllShowDeliveryList(){
    this.cartServiceApi.ShowDeliverySlot().subscribe(res => {
      var result:any=res;
      this.DeliverySlotList=result.ResponseMessage;
      this.DeliverySlotList.forEach(element => {
        element.Start_Time = this.datePipe.transform(element.Start_Time, 'h:mm a')
        element.End_Time = this.datePipe.transform(element.End_Time, 'h:mm a')
        //return element.Start_Time;
      });
    })
  } 
 
}
