import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DashboardService } from 'src/services/dashboard.service/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/services/common-services/common.service';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-dashboard-beauti-and-care-offers',
  templateUrl: './dashboard-beauti-and-care-offers.component.html',
  styleUrls: ['./dashboard-beauti-and-care-offers.component.css']
})
export class DashboardBeautiAndCareOffersComponent implements OnInit {
  customOptions: OwlOptions;
  offerData: any = [];
  displayType: number = 2;
  @Output() addToCartCallBack = new EventEmitter();
  @Input() offerSection: any = {};
  @Output() openOfferPageCallBack = new EventEmitter();
  @Output() showProductDetailsCallBack = new EventEmitter();
  constructor(private _dashboardService: DashboardService,
    private toastr: ToastrService,
    public _commonService: CommonService) {
    this.displayType = 1;
    //this._commonService.CalculatePercentage(4,20);
    this.customOptions = {
      loop: false,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: true,
      navSpeed: 700,
      //autoplay: true,
      navText: ['', ''],
      responsive: {
        0:
        {
          items: 1
        },
        575:
        {
          items: 2
        }, 840:
        {
          items: 4
        }, 1000:
        {
          items: 6
        },
        1440:
        {
          items: 8
        }
      },
      nav: true
    }
  }

  ngOnInit() {
  }

  addToCart(product) {
    this.addToCartCallBack.emit(product)
  }

  openOfferPage(offer) {
    this.openOfferPageCallBack.emit(offer);
  }

  showDetail(product) {
    this.showProductDetailsCallBack.emit(product);
  }

  openProductDetails(product) {
    this.showProductDetailsCallBack.emit(product);
  }
  openOffer(mustHave) {
    this.openOfferPageCallBack.emit(mustHave);
  }


}
