import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from '../http.service/httpService';

@Injectable({
  providedIn: 'root'
})
export class MyAccountService {
  serverURL = environment.baseUrl;
  constructor(private _httpService: HttpService) { }
  addAccount(data: any = {}) {
    debugger
    var jsonData: any = JSON.stringify(data);
    return this._httpService.postGetCart("api/ShippingAddress/AddBuyer", jsonData);
  }
  addAccountNew(data: any = {}) {
    var jsonData: any = JSON.stringify(data);
    return this._httpService.postGetCart("api/ShippingAddress/AddBuyer", jsonData);
  }
  getBuyer(BuyerId) {
    return this._httpService.get("api/ShippingAddress/GetBuyer?BuyerID=" + BuyerId);
  }
  getDeliveryAddress(BuyerId) {
    return this._httpService.get("api/ShippingAddress/GetAddress?BuyerId=" + BuyerId);
    //return this._httpService.get("api/BillingAddress/GetAddress?BuyerId=" + BuyerId);
  }
  getBillingAddress(BuyerId) {
    return this._httpService.get("api/BillingAddress/GetAddress?BuyerId=" + BuyerId);
    //return this._httpService.get("api/ShippingAddress/GetAddress?BuyerId=" + BuyerId);
  }
  addDeliveryAddress(data) {
    var jsonData: any = JSON.stringify(data);
    return this._httpService.postGetCart("api/ShippingAddress/AddAddress", jsonData);
    // return this._httpService.postGetCart("api/BillingAddress/AddAddress", jsonData);
  }
  addBillingAddress(data) {
    var jsonData: any = JSON.stringify(data);
    return this._httpService.postGetCart("api/BillingAddress/AddAddress", jsonData);
    //return this._httpService.postGetCart("api/ShippingAddress/AddAddress", jsonData);
  }
  getMyOrders(BuyerId) {
    return this._httpService.get("api/Orders/MyOrders?id=" + BuyerId);
  }
  cancelOrder(apiReqData) {
    return this._httpService.postGetCart("api/Orders/OrderCancellation",apiReqData);
  }
}

