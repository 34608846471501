import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FacebookModel, GoogleModel } from 'src/models/signup-login-models/signup.login.models';
import { SignUpLoginService } from 'src/services/signup-login.service/signup.login.service';
import { YessConstants } from 'src/shared/constants';

@Component({
  selector: 'app-facebook-google-login',
  templateUrl: './facebook-google-login.component.html',
  styleUrls: ['./facebook-google-login.component.css']
})
export class FacebookGoogleLoginComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

}
