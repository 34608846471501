import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mismatch-delivery-and-store-alert',
  templateUrl: './mismatch-delivery-and-store-alert.component.html',
  styleUrls: ['./mismatch-delivery-and-store-alert.component.css']
})
export class MismatchDeliveryAndStoreAlertComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
