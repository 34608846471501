import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-change-store-confirmation',
  templateUrl: './change-store-confirmation.component.html',
  styleUrls: ['./change-store-confirmation.component.css']
})
export class ChangeStoreConfirmationComponent implements OnInit {

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
  }
  closePopUp() {
    this.activeModal.dismiss();
  }
  continue() {
    this.activeModal.close();
  }
}
