import { Component, OnInit, HostListener } from '@angular/core';
import { CategoryService } from 'src/services/category.service/category.service';
import { ProductModel, SortByModel, AddToCartModel, CategoryFilter } from 'src/models/category-models/category.models';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { SessionStorageService } from 'src/services/session-storage-service/session.storage.service';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/services/shared-service/shared.service';
import { ToastrService } from 'ngx-toastr';
import { CookieStorageService } from 'src/services/cookie-service/cookie.service';
import { YessConstants } from 'src/shared/constants';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DashboardService } from 'src/services/dashboard.service/dashboard.service';
import { BannerModel } from 'src/models/dashboard-models/dashboard.models';
import { timeStamp } from 'console';
import { CartService } from 'src/services/cart.service/cart.service';

@Component({
  selector: 'yess-category',
  templateUrl: './yess-category.component.html',
  styleUrls: ['./yess-category.component.css']
})
export class YessCategoryComponent implements OnInit {
  paramData: any = {};
  BreadCombArray: any = [];
  SearchText: string = "";
  categoryFilter = {} as CategoryFilter;
  LeftSidefilters: any = {};
  isLoadMoreSpinnerShow: boolean = false;
  buyerId: number = null;
  businessId: string = '';
  selectedCatData: any = {};
  getAllProductsSericeSubscription: Subscription;
  parameterServiceSubscription: Subscription;
  addToCartServiceSubscription: Subscription;
  productList: ProductModel[] = [];
  sortBy: SortByModel[] = [];
  totalRecords: number = 0;
  customOptions: OwlOptions;
  slidingBanners: BannerModel[] = [];
  constructor(private _categoryService: CategoryService,
    private _router: Router,
    private _activatedroute: ActivatedRoute,
    private _sessionStorageService: SessionStorageService,
    private _sharedService: SharedService,
    private toastr: ToastrService,
    private _cookieStorageService: CookieStorageService,
    private _dashboardService: DashboardService,
    private _CartService:CartService) {
    this.customOptions = {
      loop: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: true,
      autoplay: true,
      navSpeed: 700,
      smartSpeed: 700,
      //navText: ['Back', 'Next'],
      navText: ['', ''],
      responsive: {
        0: {
          items: 2
        },
      },
      nav: true
    }
    this.sortBy = [
      { Id: 1, Name: "Price (Low to High)", Order: "ASC", Value: 'Price' },
      { Id: 2, Name: "Price (High to Low)", Order: "DESC", Value: 'Price' },
      { Id: 3, Name: "Discount", Order: "ASC", Value: 'Discount' }
    ]
    this.buyerId = this._cookieStorageService.getBuyerId();
    this.businessId = this._cookieStorageService.getStoreId();
    this.LeftSidefilters = {
      categoriesList: [],
      brandsList: [],
      menufacturesList: [],
    }
    this.getAllBanners(this.businessId);
    debugger
    this._activatedroute.params.subscribe((params: any) => {
      //const param = Object.assign({}, params);
      this.resetFilter();
      this.categoryFilter.IsRefreshList = true;
      this.paramData.Id = params.Id;
      this.paramData.Name = params.Name;
      this.paramData.Type = params.Type;
      this.setValueByType();
    });
    // this._activatedroute.queryParams.subscribe((params: any) => {
    //   const param = Object.assign({}, params);
    //   this.resetFilter();
    //   this.categoryFilter.IsRefreshList = true;
    //   this.paramData = JSON.parse(param.Data);
    //   this.paramData.Type = param.Type;
    //   this.setValueByType();
    // });
  }
  setValueByType() {
    debugger;
    switch (this.paramData.Type) {
      case 'Category':
        this.categoryFilter.CategoryId = this.paramData.Id;
        this.SearchText = this.paramData.Name;
        break;
      case 'SubCategory':
        this.categoryFilter.SubCategoryId = this.paramData.Id;
        this.SearchText = this.paramData.Name;
        break;
      case 'Brand':
        this.categoryFilter.Brands = this.paramData.Id + '';
        //this.categoryFilter.CategoryId = this.paramData.Id;
        this.SearchText = this.paramData.Name;
        break;
      case 'Offer':
        this.categoryFilter.OfferCode = this.paramData.Id;
        this.SearchText = this.paramData.Name;
        break;
      case 'SearchText':
        this.categoryFilter.ProductNameSearch = this.paramData.Name;
        this.SearchText = this.paramData.Name;
        break;
      case 'WorldFood':
        debugger;
        this.categoryFilter.CategoryId=this._CartService.GetCatIdCatCode.CategoryId;
        this.SearchText=this._CartService.GetCatIdCatCode.CategoryCoe;
        //var res=this._CartService.GetCatIdCatCode.CategoryCoe;
        //this.SearchText='World_Food';
        this._CartService.WorldFoodChange.next();
        this.getAllProducts(this.categoryFilter);
        break;
    }
    if (this.paramData.Type != 'WorldFood') {
      this.getAllProducts(this.categoryFilter);
    }
  }
  resetFilter() {
    this.categoryFilter = {
      StoreId: +this.businessId,
      CategoryId: 0,
      SubCategoryId: 0,
      Brands: "",
      Manufacturers: "",
      PageSize: YessConstants.PageSize,
      PageNo: 1,
      Orderby: "Price",
      OrderDirection: "ASC",
      Priceranges: "",
      Offerid: 0,
      OfferCode: "",
      IsRefreshList: false,
    }
  }

  getAllProducts(categoryFilter: CategoryFilter, IsScroll: boolean = false) {
    this.isLoadMoreSpinnerShow = true;
    if (this.getAllProductsSericeSubscription) {
      this.getAllProductsSericeSubscription.unsubscribe();
    }
    this.getAllProductsSericeSubscription = this._categoryService.getAllProducts(categoryFilter).subscribe(res => {
      let result: any = res;
      this.isLoadMoreSpinnerShow = false;

      if (!result.IsError) {
        if (categoryFilter.IsRefreshList) {
          const categoriesList: [] = result.ResponseMessage && result.ResponseMessage.Filter_Categories ? result.ResponseMessage.Filter_Categories : []
          const brandsList: [] = result.ResponseMessage && result.ResponseMessage.Filter_Brands ? result.ResponseMessage.Filter_Brands : []
          const menufacturesList: [] = result.ResponseMessage && result.ResponseMessage.Filter_Manufacturers ? result.ResponseMessage.Filter_Manufacturers : []
          this.LeftSidefilters = {};
          this.LeftSidefilters = {
            categoriesList: categoriesList,
            brandsList: brandsList,
            menufacturesList: menufacturesList,
          }
        }
        const productlist: [] = result.ResponseMessage && result.ResponseMessage.Products ? result.ResponseMessage.Products : [];
        if (productlist.length) {
          if (IsScroll) {
            this.productList.push.apply(this.productList, productlist);
          } else {
            this.productList = productlist;
            this.totalRecords = result.ResponseMessage && result.ResponseMessage.ResultCount ? result.ResponseMessage.ResultCount : 0;
          }
        }
        else {
          this.productList = [];
          this.totalRecords = 0;
        }
        this.categoryFilter.IsRefreshList = false;
      }
    }, err => {
      this.isLoadMoreSpinnerShow = false;
    })
  }
  ngOnInit() {
  }
  OpenDetailPage(product: any) {
    const url = "/product-detail/" + product.ItemCode + "/" + product.CategoryId;
    this._router.navigate([url]);
  }
  addToCart(productToAdd: any) {
    const ssid: any = this._cookieStorageService.getSessionId();
    const product: AddToCartModel = {
      "StoreId": +this.businessId,
      "ProductId": +productToAdd.ProductId,
      "SessionId": ssid,
      "SKU": productToAdd.SKU ? productToAdd.SKU : "",
      "Qty": productToAdd.Quantity,
      "BuyerId": +this.buyerId ? this.buyerId : 0,
    }
    this.addToCartAPICall(product);
  }
  addToCartAPICall(addProduct: AddToCartModel) {
    if (this.addToCartServiceSubscription) {
      this.addToCartServiceSubscription.unsubscribe();
    }
    this.addToCartServiceSubscription = this._categoryService.addToCart(addProduct).subscribe(res => {

      let result: any = res;
      if (!result.IsError) {
        //this.toastr.success(result.ResponseMessage);
        this.toastr.success("Product successfully added to cart ");
        this._sharedService.updateCartCount();
      } else {
        this.toastr.error(result.ErrorMessage);
      }
    })
  }

  @HostListener('document:scroll')
  scrollHandler(): void {
    let windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
    let body = document.body, html = document.documentElement;
    let docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight) - 800;
    let windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= docHeight) {
      this.loadProductsOnScroll();
    }
  }
  loadProductsOnScroll() {
    if (this.productList && this.productList.length) {
      if (this.productList.length < this.totalRecords) {
        this.categoryFilter.PageSize = YessConstants.PageSize;
        if (this.productList.length == (this.categoryFilter.PageNo * this.categoryFilter.PageSize)) {
          this.categoryFilter.PageNo = this.categoryFilter.PageNo + 1;
          this.categoryFilter.IsRefreshList = false;
          this.getAllProducts(this.categoryFilter, true);
        }
      }
    }
  }

  sortByList(Id: number) {
    var selectedSort = {} as SortByModel;
    selectedSort = this.sortBy.find(x => x.Id == Id);
    this.categoryFilter.Orderby = selectedSort.Value;
    this.categoryFilter.OrderDirection = selectedSort.Order;
    this.categoryFilter.IsRefreshList = false;
    this.categoryFilter.PageNo = 1;
    this.productList = [];
    this.getAllProducts(this.categoryFilter, false);
  }
  onSelectionFilterCallback(sideFilters) {
    debugger
    this.categoryFilter.PageNo = 1;
    this.categoryFilter.Brands = this.getCommaSeparatedString(sideFilters.brandsList);
    this.categoryFilter.Manufacturers = this.getCommaSeparatedString(sideFilters.menufacturesList);
    this.categoryFilter.Categories = this.getCommaSeparatedString(sideFilters.categoriesList);
    if (sideFilters && sideFilters.categoriesList && sideFilters.categoriesList.length) {
      this.categoryFilter.CategoryId = sideFilters.categoriesList[0].Id;
      this.categoryFilter.SubCategoryId = 0;//sideFilters.categoriesList.Id;
      //this.categoryFilter.IsRefreshList = true;
      //this.SearchText = sideFilters.categoriesList[0].CategoryName;
      // if(this.BreadCombArray && !this.BreadCombArray.some(a=>a.Name==this.paramData.Name && a.Type==this.paramData.Type ))
      // {
      //   this.BreadCombArray.push(this.paramData);
      // }
    }
    else if (this.paramData && this.paramData.Type === 'Category') {
      this.categoryFilter.CategoryId = this.paramData.Id;
      this.SearchText = this.paramData.Name;
      this.categoryFilter.SubCategoryId = 0;//sideFilters.categoriesList.Id;
    }
    else {
      this.categoryFilter.CategoryId = 0;
      //this.SearchText = '';
      this.categoryFilter.SubCategoryId = 0;
    }

    if (this.paramData && this.paramData.Type === 'Brand') {
      this.categoryFilter.Brands = this.paramData.Id;
    }



    //if (sideFilters && sideFilters.categoriesList && sideFilters.categoriesList.Id) {
    //  this.categoryFilter.CategoryId = 0;
    //  this.categoryFilter.IsRefreshList = true;
    //  this.categoryFilter.SubCategoryId = sideFilters.categoriesList.Id;
    //  this.SearchText = sideFilters.categoriesList.CategoryName;
    //  if (this.selectedCatData && this.selectedCatData.BrandId) {
    //    this.categoryFilter.Brands = this.selectedCatData.BrandId;
    //  }
    //  this.selectedCatData.SubCategory = {
    //    SubCategoryId: sideFilters.categoriesList.Id,
    //    SubCategoryName: sideFilters.categoriesList.CategoryName
    //  }
    //}
    this.productList = [];
    this.getAllProducts(this.categoryFilter, false);
  }
  getCommaSeparatedString(listArray: any = []) {
    var returnString: string = "";
    let data: [] = listArray.filter(opt => opt.isChecked === true);
    data.forEach(res => {
      returnString = returnString + "," + res["Id"];
    })
    returnString = returnString.substring(1);
    return returnString;
  }
  removeFromArray(myArray, item): [] {
    const index = myArray.indexOf(item, 0);
    if (index > -1) {
      myArray.splice(index, 1);
    }
    return myArray;
  }
  loadParticularBreadComb(param) {
    switch (param.Type) {
      case 'Category':
        this.categoryFilter.SubCategoryId = 0;
        this.categoryFilter.CategoryId = this.paramData.Id;
        this.SearchText = this.paramData.Name;
        break;
      case 'SubCategory':
        this.categoryFilter.CategoryId = 0;
        this.categoryFilter.SubCategoryId = this.paramData.Id;
        this.SearchText = this.paramData.Name;
        break;
      case 'Brand':
        this.categoryFilter.SubCategoryId = 0;
        this.categoryFilter.Brands = this.paramData.Id;
        this.categoryFilter.CategoryId = this.paramData.Id;
        this.SearchText = this.paramData.Name;
        break;
      case 'Offer':
        this.categoryFilter.CategoryId = 0;
        this.categoryFilter.SubCategoryId = 0;
        this.categoryFilter.OfferCode = this.paramData.Id;
        this.SearchText = this.paramData.Name;
        break;
      case 'SearchText':
        this.resetFilter();
        this.categoryFilter.ProductNameSearch = this.paramData.Name;
        this.SearchText = this.paramData.Name;
        break;
    }
    this.BreadCombArray = [];
    this.categoryFilter.IsRefreshList = true;
    this.getAllProducts(this.categoryFilter);

  }

  getAllBanners(businessId) {
    this._dashboardService.loadBanner('InnerBanner', businessId).subscribe(res => {
      let result: any = res;
      if (!result.IsError) {
        this.slidingBanners = result.ResponseMessage;
        if (this.slidingBanners.length == 0) {
          this.slidingBanners = [
            { BannerImage: 'assets/images/categories-banner.png', BusinessId: 0, ReferenceId: 0, ReferenceCode: '', TargetUrl: '', Id: 0, BannerType: '', BannerCode: '' }
          ];
        }
      } else {
        this.toastr.error(result.ErrorMessage);
      }
    }, err => {
      this.toastr.error("Something went wrong while getting banners ");
    })

  }
  bannerClick(banner) {
    if (banner.BannerType) {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          "Type": 'Offer',
          "Data": JSON.stringify({
            "Id": banner.ReferenceCode,
            "Name": banner.ReferenceCode,
          }),
        }
      };
      //this._router.navigate(['offers'], navigationExtras);
      this._router.navigate(['category/Offer/' + banner.ReferenceCode + '/' + banner.ReferenceCode]);
    }
  }

}

