import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DashboardService } from 'src/services/dashboard.service/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { BannerModel } from 'src/models/dashboard-models/dashboard.models';

@Component({
  selector: 'app-dashboard-testimonial',
  templateUrl: './dashboard-testimonial.component.html',
  styleUrls: ['./dashboard-testimonial.component.css']
})
export class DashboardTestimonialComponent implements OnInit {
  @Input() businessId: number = 0;
  @Output() MainSliderImageClickCallBack = new EventEmitter();
  TestimonialList: any = [];
  BannerImage: BannerModel={BannerImage:'/assets/images/banner02.jpg',BusinessId: 0,ReferenceId:0,ReferenceCode:'',TargetUrl: '',Id: 0,BannerType: '',BannerCode: ''};
  customOptions: OwlOptions;
  constructor(private _dashboardService: DashboardService,
    private toastr: ToastrService) {
    this.customOptions = {
      loop: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: false,
      dots: false,
      autoplay: true,
      navSpeed: 700,
      smartSpeed: 700,
      //navText: ['Back', 'Next'],
      navText: ['', ''],
      responsive: {
        0: {
          items: 1
        },
      },
      nav: true
    }
  }

  ngOnInit() {
    this.getAllTestimonialList(this.businessId);
    this.getAllBanners(this.businessId);
  }
  getAllTestimonialList(businessId) {
    this._dashboardService.getAllTestimonialList(businessId).subscribe(res => {
      let result: any = res;
      if (!result.IsError) {
        this.TestimonialList = result.ResponseMessage;
      } else {
        //this.toastr.error(result.ErrorMessage);
        this.TestimonialList = [];
      }

    }, err => {
      this.toastr.error("Something went wrong while getting testimonial");

    });
  }
  
  getAllBanners(businessId) {
    this._dashboardService.loadBanner('HomePageBottomBanner',businessId).subscribe(res => {
      let result: any = res;
      if (!result.IsError) {
        this.BannerImage = result.ResponseMessage[0];
      } else {
        this.toastr.error(result.ErrorMessage);
      }
    }, err => {
      this.toastr.error("Something went wrong while getting banners ");
    })

  }
  imageClick(banner) {
    this.MainSliderImageClickCallBack.emit(banner);
  }

}
