import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class YessEnums {
  LoginSignUpTab: any;
  FiltersEnum: any;
  OrderStatusEnum: any;
  StaticHtmlPageByType: any;
  constructor() {
    this.LoginSignUpTab = LoginSignUpTab;
    this.FiltersEnum = FiltersEnum;
    this.OrderStatusEnum = OrderStatusEnum;
    this.StaticHtmlPageByType=StaticHtmlPageByType;
  }
}

export enum LoginSignUpTab {
  LoginTab = 1,
  SignUpTab = 2,
}

export enum FiltersEnum {
  Category = 1,
  Brand = 2,
  Manufacturer = 3
}
//export enum OrderStatusEnum {
//  Placed = "Placed",
//  InProcess = "In Process",
//  Packed = "Packed",
//  OnTheWay = "On The Way",
//  Delivered = "Delivered",
//  USERCANCELLED = "USERCANCELLED",

//}
export enum OrderStatusEnum {
  RECEIVED = "RECEIVED",
  PROCESSING = "PROCESSING",
  READYTOSHIP = "READYTOSHIP",
  DISPATCHED = "DISPATCHED",
  DELIVERED = "DELIVERED",
  USERCANCELLED = "USERCANCELLED",
  ADMINCANCELED = "ADMINCANCELED"

}
export enum StaticHtmlPageByType{
  FAQ="FAQ",
  PrivacyPolicy="PrivacyPolicy",
  TermConditions="TermConditions",
  Blog="Blog",
  AboutUs="AboutUs",
  GreenYess="GreenYess",
  YessInMedia="YessInMedia",
}













