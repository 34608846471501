import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { YessEnums } from 'src/shared/enum/enum';
import { YessConstants } from 'src/shared/constants';
import { Subject, Subscription } from 'rxjs';
import { SignUpLoginService } from 'src/services/signup-login.service/signup.login.service';
import { NgForm } from '@angular/forms'
import { ToastrService } from 'ngx-toastr';
import { CookieStorageService } from 'src/services/cookie-service/cookie.service';
import { UpdateAccountModel } from 'src/models/my-account-models/myaccount.models';
import { MyAccountService } from 'src/services/myaccount.service/myaccount.service';
@Component({
  selector: 'app-sign-in-sign-up-popup',
  templateUrl: './sign-in-sign-up-popup.component.html',
  styleUrls: ['./sign-in-sign-up-popup.component.css']
})
export class SignInSignUpPopupComponent implements OnInit {
  @ViewChild("fl", { static: false }) loginForm: NgForm;
  @ViewChild("f", { static: false }) signUpForm: NgForm;
  @ViewChild("flotp", { static: false }) loginOTPForm: NgForm;
  @ViewChild("fsotp", { static: false }) signUpOTPForm: NgForm;
  IsWaitingForSignUpOTP: boolean = false;
  IsWaitingForSignInOTP: boolean = false;
  IsFillSignUpInformation: boolean = false;
  @Input() activeTab: number = 0;
  enum: any = {};
  phonePattern: string = "";
  signUpModel: any = {};
  loginModel: any = {};
  LoginOTP: number = null;
  signUpOTP: number = null;
  signUpByNumberServiceSubscription: Subscription;
  mobilePhonePattern: string = ""
  emailPattern: string = "";
  updateAccountModel: any = {} as UpdateAccountModel;

  constructor(private activeModal: NgbActiveModal,
    private _yessEnums: YessEnums,
    private _signUpLoginService: SignUpLoginService,
    protected changeDetectorRef: ChangeDetectorRef,
    private toastr: ToastrService,
    private _cookieStorageService: CookieStorageService,
    private _myAccountService: MyAccountService,    
    private el: ElementRef) {
    this.enum = this._yessEnums;
    this.phonePattern = YessConstants.Common.MobileNumberlength;
    this.mobilePhonePattern = YessConstants.Common.EmailPhonePattern;
    this.emailPattern = YessConstants.Common.EmailPatternCustomer;
  }

  ngOnInit() {
  }
  activateTab(currTab) {
    this.loginForm.resetForm();
    this.signUpForm.resetForm();
    this.changeDetectorRef.detectChanges();
    this.activeTab = currTab;
  }
  closePopUp(action, dataToSend) {
    const data = {
      action: action,
      data: dataToSend,
    }
    this.activeModal.close(data);
  }
  //-------register by number start--------------------------------------//
  backRegister() {
    this.IsWaitingForSignUpOTP = false;
  }
  changeRegisterCredential() {
    this.backRegister();
    this.signUpForm.resetForm();
  }
  signUpByNumber(signUpModel) {    
    const phoneEmailBox = this.el.nativeElement.querySelector('form [name=signupopt]');
    if(phoneEmailBox){
    phoneEmailBox.focus();
    }
    this.signUpByNumberAPICall(signUpModel);
  }
  resendRegisterOTP() {
    this.signUpByNumberAPICall(this.signUpModel);
  }
  signUpByNumberAPICall(signUpModel) {
    if (this.signUpByNumberServiceSubscription) {
      this.signUpByNumberServiceSubscription.unsubscribe();
    }
    this.signUpByNumberServiceSubscription = this._signUpLoginService.signUpByNumber(signUpModel.Phone).subscribe(res => {
      let result: any = res;
      if (result.IsError) {
        this.toastr.error(result.ErrorMessage);
      } else {
        this.IsWaitingForSignUpOTP = true;
        //this.toastr.success("OTP sent to mobile number");   
        this.toastr.success(result.ResponseMessage);
        const phoneEmailBox = this.el.nativeElement.querySelector('form [name=signupopt]');
        if(phoneEmailBox){
        phoneEmailBox.focus();
        }
        //MEssage Service not working so temporerly OTP Will display is success message 
        //this.toastr.success(result.ResponseMessage);
      }
    }, err => {
    })
  }
  validateSignUpOTP() {
    this.validateSignUpOTPAPICall(this.signUpModel.Phone, this.signUpOTP);
  }
  validateSignUpOTPAPICall(phoneNumber: number, OTP: number) {
    this._signUpLoginService.validateSignUpOTP(phoneNumber, OTP).subscribe(res => {
      let result: any = res;
      if (result.IsError) {
        this.toastr.error(result.ResponseMessage);
      } else {
        this.IsFillSignUpInformation = true;
      }
    });
  }
  SaveSignUpUserInformation() {
    this.updateAccountModel.Phone = this.signUpModel.Phone;
    this.updateAccountModel.Landline = this.signUpModel.Landline;
    this.SaveSignUpUserInformationAPICall(this.updateAccountModel);
  }
  SaveSignUpUserInformationAPICall(updateAccountModel: UpdateAccountModel) {
    updateAccountModel.DOB = "";
    updateAccountModel.Landline = "";
    this._myAccountService.addAccount(updateAccountModel).subscribe(res => {
      let result: any = res;
      if (!result.IsError) {
        this._cookieStorageService.setUser(result.buyer);
        this.toastr.success("Signup Successfully");
        this.closePopUp(true, null);
      } else {
        this.toastr.error(result.ErrorMessage);
      }
    });
  }

  //  -------------register by number end-----------------------------------//

  
  loginNumberOrEmail(loginModel) {    
    const phoneEmailBox = this.el.nativeElement.querySelector('form [name=signinOTP]');
    if(phoneEmailBox){
    phoneEmailBox.focus();
    }
    const reqData = {     
      "Email": isNaN(loginModel.PhoneEmail) ? loginModel.PhoneEmail : null,
      "Phone": isNaN(loginModel.PhoneEmail) ? null: loginModel.PhoneEmail,
    }
    this.loginNumberOrEmailAPICall(reqData);
  }
  loginNumberOrEmailAPICall(reqData) {
    this._signUpLoginService.signInByNumberOrEmail(reqData).subscribe(res => {
      let result: any = res;
      if (result.IsError) {
        this.IsWaitingForSignInOTP = false;
        this.toastr.error(result.ErrorMessage);
      } else {
        this.IsWaitingForSignInOTP = true;
        //this.toastr.success("OTP sent to registerd Mobile Or Email");   
        this.toastr.success(result.ErrorMessage);
        const phoneEmailBox = this.el.nativeElement.querySelector('form [name=signinOTP]');
        if(phoneEmailBox){
        phoneEmailBox.focus();
        }
        //MEssage Service not working so temporerly OTP Will display is success message 
        //this.toastr.success(result.ErrorMessage);
      }

    })
  }
  backLogin() {
    this.IsWaitingForSignInOTP = false;
  }

  changeLoginCredential() {
    this.backLogin();
    this.loginForm.resetForm();
  }

  resendLoginOTP() {
    this.loginNumberOrEmail(this.loginModel);
  }
  validateLoginOTP() {
    const reqData = {
      EmailId: isNaN(this.loginModel.PhoneEmail) ? this.loginModel.PhoneEmail : null,
      Phone: isNaN(this.loginModel.PhoneEmail) ? null : this.loginModel.PhoneEmail,
      OTP: this.LoginOTP
    }
    this.validateLoginOTPAPICall(reqData);
  }
  validateLoginOTPAPICall(data) {
    this._signUpLoginService.ValidateLoginOTP(data).subscribe(res => {
      let result: any = res;
      if (!result.IsError) {
        this._cookieStorageService.setUser(result);
        this.toastr.success("Login Successfully");
        this.closePopUp(true, null);
      } else {
        this.toastr.error(result.ErrorMessage);
      }
    })
  }

}
