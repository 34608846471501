import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DashboardService } from 'src/services/dashboard.service/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CommonService } from 'src/services/common-services/common.service';

@Component({
  selector: 'app-dashboard-clean-house-hold',
  templateUrl: './dashboard-clean-house-hold.component.html',
  styleUrls: ['./dashboard-clean-house-hold.component.css']
})
export class DashboardCleanHouseHoldComponent implements OnInit {
  @Input() offerSection: any = {};
  @Output() cleanHouseHoldImageClickCallBack = new EventEmitter();
  @Output() addToCartCallBack = new EventEmitter();
  customOptions: OwlOptions;
  displayType: number = 1;
  CleaningAndHouseHoldProducts: any = [];
  constructor(private _dashboardService: DashboardService,
    private toastr: ToastrService,
    public _commonService: CommonService) {
    this.displayType = 1;
    this.customOptions = {
      loop: false,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: true,
      navSpeed: 700,
      //autoplay: true,
      navText: ['', ''],
      responsive: {
        0:
        {
          items: 1
        },
        575:
        {
          items: 2
        }, 840:
        {
          items: 4
        }, 1000:
        {
          items: 6
        },
        1440:
        {
          items: 8
        }
      },
      nav: true
    }
  }

  ngOnInit() {

  }
  ImageClick(cat) {
    this.cleanHouseHoldImageClickCallBack.emit(cat);
  }
  
  addToCart(product) {
    this.addToCartCallBack.emit(product)
  }
}
