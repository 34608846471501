import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { log } from 'util';
import { Router } from '@angular/router';
import { UpdateAccountModel } from 'src/models/my-account-models/myaccount.models';
import { YessConstants } from 'src/shared/constants';
import { MyAccountService } from 'src/services/myaccount.service/myaccount.service';
import { CookieStorageService } from 'src/services/cookie-service/cookie.service';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { AreaListModel } from 'src/models/layout-models/layout.models';

@Component({
  selector: 'app-yess-my-account-page',
  templateUrl: './yess-my-account-page.component.html',
  styleUrls: ['./yess-my-account-page.component.css']
})
export class YessMyAccountPageComponent implements OnInit {

  latitude: number = 0;
  longitude: number = 0;
  zoom: number = 0;
  address: string = '';
  private geoCoder: google.maps.Geocoder | any;
  @ViewChild('search', { static: false }) set content(content: ElementRef) {
    if(content) { // initially setter gets called with undefined
        this.searchElementRef = content;
        this.setAutocompleteInput();
    }
 }
  public searchElementRef: ElementRef | any;
  sameAs: boolean = false;
  buyerId: number = 0;
  storeInfo: AreaListModel = { StoreId: 0, DeliveryRangeKm: 0, Latitude: 0, Logitude: 0 };
  _accountHelpingServiceSubscription: Subscription;
  updateAccountModel = {} as UpdateAccountModel;
  updateDeliveryAddressModel: any = {};
  shippingDetailList: any = [];
  billingDetailList: any = [];
  updateBillingAddressModel: any = {};
  emailPattern: string = "";
  phonePattern: string = "";
  isShowUpdateAccountButtonLoader: boolean = false;
  isShowUpdateDeliveryAddressButtonLoader: boolean = false;
  isShowUpdateBillingAddressButtonLoader: boolean = false;
  isAddEditingShippingAddress: boolean = false;
  isAddEditingBillingAddress: boolean = false;
  constructor(private toastr: ToastrService, private scroller: ViewportScroller,
    public _router: Router,
    private _myAccountService: MyAccountService,
    private _cookieStorageService: CookieStorageService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone) {
    this.emailPattern = YessConstants.Common.EmailPatternCustomer;
    this.phonePattern = YessConstants.Common.MobileNumberlength;
    const localbuyerId = _cookieStorageService.getBuyerId();
    this.buyerId = localbuyerId ? localbuyerId : 0;
    this.storeInfo = this._cookieStorageService.getStoreInfo();
    this.updateBillingAddressModel = {};
    this.updateDeliveryAddressModel = {};
  }

  ngOnInit() {
    this.sameAs = false;
    this.getBillingAddressAPIcall(this.buyerId);
    this.getBuyerAccountAPICall(this.buyerId);
    this.getShipingAddressAPIcall(this.buyerId);
    //this.setAutocompleteInput();
  }
  getShipingAddressAPIcall(buyerId) {
    this._myAccountService.getDeliveryAddress(buyerId).subscribe(res => {
      let result: any = res;
      if (result.ShippingAddress && result.ShippingAddress.length != 0) {
        //const updateDeliveryAddressModel = result.ShippingAddress[0];
        //this.updateDeliveryAddressModel= updateDeliveryAddressModel;
        this.shippingDetailList = result.ShippingAddress;
      }
      else {
        this.updateDeliveryAddressModel = {};
      }

    })
  }
  getBuyerAccountAPICall(buyerId) {
    this._myAccountService.getBuyer(buyerId).subscribe(res => {
      let result: any = res;
      if (result.IsError) {
        this.updateAccountModel = {} as UpdateAccountModel;
      } else {
        if (result.ResponseMessage) {
          const localUpdateAccountModel = result.ResponseMessage;
          var BuyerID = result.ResponseMessage.CustomerId;
          var FistName = result.ResponseMessage.FirstName;
          var LastName = result.ResponseMessage.FirstName;
          var DATEOFBIRTH: ""
          var dob = result.ResponseMessage.DOB;
          dob = dob.split('T')[0];
          var Email = result.ResponseMessage.Email;
          var Phone = result.ResponseMessage.Phone;
          localUpdateAccountModel.CustomerId = BuyerID;
          localUpdateAccountModel.FistName = FistName;
          localUpdateAccountModel.LastName = LastName;
          localUpdateAccountModel.DOB = dob;
          localUpdateAccountModel.Email = Email;
          localUpdateAccountModel.Phone = Phone;
          this.updateAccountModel = localUpdateAccountModel;
        } else {
          this.updateAccountModel = {} as UpdateAccountModel;
        }
      }
    });
  }
  getBillingAddressAPIcall(buyerId) {
    this._myAccountService.getBillingAddress(buyerId).subscribe(res => {
      let result: any = res;
      if (result.BillingAddress && result.BillingAddress.length != 0) {
        // const billingAddressModel=result.BillingAddress[0];
        // this.updateBillingAddressModel=billingAddressModel;
        this.billingDetailList = result.BillingAddress;
      }
      else {
        this.updateBillingAddressModel = {};
      }
    })
  }
  updateAccount(updateAccountModel: any) {
    this.updateAccountAPICall(updateAccountModel);
  }
  updateAccountAPICall(updateAccountModel: any) {
    this.isShowUpdateAccountButtonLoader = true;
    this._myAccountService.addAccount(updateAccountModel).subscribe(res => {
      let result: any = res;
      this.toastr.success(result.ErrorMessage);
      this.isShowUpdateAccountButtonLoader = false;
    }, err => {
      this.isShowUpdateAccountButtonLoader = false;
    })
  }
  AddUpdateAddress(updateDeliveryAddressModel: any) {
    updateDeliveryAddressModel.CustomerId = this.buyerId;
    updateDeliveryAddressModel.Latitude = updateDeliveryAddressModel.Latitude + '';
    updateDeliveryAddressModel.Longitude = updateDeliveryAddressModel.Longitude + '';
    updateDeliveryAddressModel.ShippingAddressesId = updateDeliveryAddressModel.ShippingAddressesId ? updateDeliveryAddressModel.ShippingAddressesId : 0;
    if (this.updateDeliveryAddressModel.AddressType)
      this.AddUpdateAddressAPICall(updateDeliveryAddressModel);
  }
  AddUpdateBillingAddress(updateBillingAddressModel: any) {
    updateBillingAddressModel.CustomerId = this.buyerId;
    updateBillingAddressModel.ShippingAddressesId = updateBillingAddressModel.BillingAddressesId ? updateBillingAddressModel.BillingAddressesId : 0;
    if (this.updateBillingAddressModel.AddressType)
      this.AddUpdateBillingAddressApiCall(updateBillingAddressModel);
  }

  AddUpdateAddressAPICall(updateDeliveryAddressModel) {
    this.isShowUpdateDeliveryAddressButtonLoader = true;
    this._myAccountService.addDeliveryAddress(updateDeliveryAddressModel).subscribe(res => {
      let result: any = res;
      this.toastr.success(result.ResponseMessage.Message);
      this.isShowUpdateDeliveryAddressButtonLoader = false;
      this.isAddEditingShippingAddress = false;
      this.updateDeliveryAddressModel = {};
      this.getShipingAddressAPIcall(this.buyerId);
    }, err => {
      this.isShowUpdateDeliveryAddressButtonLoader = false;
    })
  }
  AddUpdateBillingAddressApiCall(updateBillingAddressModel) {
    this.isShowUpdateBillingAddressButtonLoader = true;
    this._myAccountService.addBillingAddress(updateBillingAddressModel).subscribe(res => {
      let result: any = res;
      this.toastr.success(result.ResponseMessage.Message);
      this.isShowUpdateBillingAddressButtonLoader = false;
      this.isAddEditingBillingAddress = false;
      this.updateBillingAddressModel = {};
      this.getBillingAddressAPIcall(this.buyerId);
    }, err => {
      this.isShowUpdateBillingAddressButtonLoader = false;
    })
  }
  jumpToSpecificLocation(elementId) {
    this.scroller.scrollToAnchor(elementId);
  }
  ngOnDestroy() {
    if (this._accountHelpingServiceSubscription) {
      this._accountHelpingServiceSubscription.unsubscribe();
    }
  }
  fillDeliveryAddress(data) {
    if (this.sameAs) {
      this.updateBillingAddressModel = data
    } else {
      this.updateBillingAddressModel = {};
    }
  }
  selectAddressType(type) {
    this.updateDeliveryAddressModel.AddressType = type;
  }
  selectBillingAddressType(type) {
    this.updateBillingAddressModel.AddressType = type;
  }
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 13;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }
  getAddress(latitude: number, longitude: number) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results: any, status: any) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 13;
          this.address = results[0].formatted_address;

          this.updateDeliveryAddressModel.Address_search = results[0].formatted_address;
          let postal_code = this.updateDeliveryAddressModel.PIN;
          let Country = this.updateDeliveryAddressModel.Country;
          let State = this.updateDeliveryAddressModel.State;
          let City = this.updateDeliveryAddressModel.City;
          results[0].address_components.forEach(
            function (ac) {
              if (ac.types.includes("route")) {
              }
              if (ac.types.includes("locality")) {
                City = ac.long_name;
              }
              if (ac.types.includes("administrative_area_level_1")) {
                State = ac.long_name;
              }
              if (ac.types.includes("postal_code")) {
                postal_code = ac.long_name;
              }
              if (ac.types.includes("country")) {
                Country = ac.long_name;
              }
            }
          );
          this.updateDeliveryAddressModel.PIN = postal_code;
          this.updateDeliveryAddressModel.Country = Country;
          this.updateDeliveryAddressModel.State = State;
          this.updateDeliveryAddressModel.City = City;
          this.updateDeliveryAddressModel.Latitude = results[0].geometry.location.lat();
          this.updateDeliveryAddressModel.Longitude = results[0].geometry.location.lng();
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }
  getLatLngByPin() {
    let cityPin = this._cookieStorageService.getCityAndPin();
    let address = /*cityPin.City + */' postal_code ' + cityPin.Pin + ', IN';
    this.geoCoder.geocode({ 'address': address }, (results: any, status: any) => {
      if (status === 'OK') {
        if (results[0]) {
          this.latitude = results[0].geometry.location.lat();
          this.longitude = results[0].geometry.location.lng();
        } else {
          //window.alert('No location found');
          this.latitude = this.storeInfo.Latitude;
          this.longitude = this.storeInfo.Logitude;
        }
      } else {
        //window.alert('Unable to get location due to: ' + status);
        this.latitude = this.storeInfo.Latitude;
        this.longitude = this.storeInfo.Logitude;
      }
      this.zoom = 13;
    });
  }
  toggleShippingForm(isOpen: boolean, model: any) {
    this.isAddEditingShippingAddress = isOpen;
    const updateAddressModel = model;
    this.updateDeliveryAddressModel = updateAddressModel;
    if(isOpen){
      //this.setAutocompleteInput();
    }
  }
  toggleBillingForm(isOpen: boolean, model: any) {
    this.isAddEditingBillingAddress = isOpen;
    const updateAddressModel = model;
    this.updateBillingAddressModel = updateAddressModel;
  }
  setAutocompleteInput() {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      if(!this.updateDeliveryAddressModel.Latitude && !this.updateDeliveryAddressModel.Longitude){
        this.getLatLngByPin();
      }
      else{
        this.latitude =+ this.updateDeliveryAddressModel.Latitude;
        this.longitude =+ this.updateDeliveryAddressModel.Longitude;
        this.zoom = 13;
      }
      //this.setCurrentLocation();
      const center = { lat: +this.storeInfo.Latitude, lng: +this.storeInfo.Logitude };
      // const southwest = { lat: center.lat - 0.06, lng: center.lng - 0.06 };
      // const northeast = { lat: center.lat + 0.06, lng: center.lng + 0.06 };
      // const newBounds = new google.maps.LatLngBounds(southwest, northeast);
      // Create a bounding box with sides ~10km away from the center point
      const defaultBounds = {
        north: center.lat + 0.1,
        south: center.lat - 0.1,
        east: center.lng + 0.1,
        west: center.lng - 0.1,
      };
      const options = {
        // locationBias:{radius: 6000, center: center},
        // LatLngBounds:{radius: 6000, center: center},
        // LatLngBoundsLiteral:{radius: 6000, center: center},
        bounds: defaultBounds,
        componentRestrictions: { country: "ind" },
        fields: ["formatted_address", "address_components", "geometry", "icon", "name"],
        strictBounds: false,
        types: ["establishment"],
      };      
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, options);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.updateDeliveryAddressModel.Address_search = place.formatted_address;
          let postal_code = this.updateDeliveryAddressModel.PIN;
          let Country = this.updateDeliveryAddressModel.Country;
          let State = this.updateDeliveryAddressModel.State;
          let City = this.updateDeliveryAddressModel.City;
          place.address_components.forEach(
            function (ac) {
              if (ac.types.includes("route")) {
              }
              if (ac.types.includes("locality")) {
                City = ac.long_name;
              }
              if (ac.types.includes("administrative_area_level_1")) {
                State = ac.long_name;
              }
              if (ac.types.includes("postal_code")) {
                postal_code = ac.long_name;
              }
              if (ac.types.includes("country")) {
                Country = ac.long_name;
              }
            }
          );
          this.updateDeliveryAddressModel.PIN = postal_code;
          this.updateDeliveryAddressModel.Country = Country;
          this.updateDeliveryAddressModel.State = State;
          this.updateDeliveryAddressModel.City = City;
          this.updateDeliveryAddressModel.Latitude = place.geometry.location.lat();
          this.updateDeliveryAddressModel.Longitude = place.geometry.location.lng();
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
        });
      });
    });
  }

  markerDragEnd($event: MouseEvent) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }
}
