export const YessConstants = {
  Common: {
    EmailPhonePattern: "^(\\d{10}|\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w+))$",
    MobileNumberlength: "^[0-9]{10,10}$",
    NamePattern: "^[a-zA-Z0-9]+$",
    EmailPatternCustomer: "^[a-zA-Z0-9_\\.-]+@([a-zA-Z0-9-]+\\.)+([a-zA-Z]+)$",//"^[a-zA-Z0-9._%+-]+@+[a-zA-Z0-9]{3,30}\\.+[a-z]{2,6}$",
    CardNumberPattern: "^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$",
    CvvPattern: "^[0-9]{3,4}$",
  },
  ProjectKeys: {
    GoogleClientId: '54131147124-c228okg8f4keo8a8je0ptiopuoomiheg.apps.googleusercontent.com',//'78052849929-1qv5hofqrmk0rrlq6anjecc4n6ar10fj.apps.googleusercontent.com',
    FacebookAppId: '507782073859087',//'334422381111688',//'5233748490051669',
  },
  PageSize: 40,
}
