import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DashboardService } from 'src/services/dashboard.service/dashboard.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dashbaord-main-cat-doodle',
  templateUrl: './dashbaord-main-cat-doodle.component.html',
  styleUrls: ['./dashbaord-main-cat-doodle.component.css']
})
export class DashbaordMainCatDoodleComponent implements OnInit {
  @Input() businessId: number = 0;
  @Output() imageClickCallBack = new EventEmitter();
  customOptions: OwlOptions;
  mainCategoryDoodles: any = [];
  constructor(private _dashboardService: DashboardService,
    private toastr: ToastrService) {
    this.customOptions = {
      loop: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: false,
      navSpeed: 700,
      autoplay: true,
      navText: ['', ''],
      responsive: {
        0: {
          items: 1
        },
        300: {
          items: 1
        },
        600: {
          items: 3
        },
        700: {
          items: 4
        },
        940: {
          items: 5
        }
      },
      nav: true
    }
  }

  ngOnInit() {
    this.getMainCategoriesDoodles(this.businessId);
  }
  getMainCategoriesDoodles(businessId) {
    this._dashboardService.getMainCategoriesDoodles(businessId).subscribe(res => {
      let result: any = res;
      if (result.IsError) {
        //this.toastr.error(result.ErrorMessage);
        this.mainCategoryDoodles = this.addDummyObjects(this.mainCategoryDoodles, 5);
      } else {
        this.mainCategoryDoodles = result.ResponseMessage;
        if (this.mainCategoryDoodles.length < 5) {
          const length = 5 - this.mainCategoryDoodles.length;
          this.mainCategoryDoodles = this.addDummyObjects(this.mainCategoryDoodles, length);
        }
      }
    }, err => {
      this.toastr.error("Something went wrong while getting main category doodles");

    })

  }
  addDummyObjects(list: any = [], doodleWantToAdd: number) {
    if (list && list.length < 5) {
      for (var i = 1; i <= doodleWantToAdd; i++) {
        const obj = {
          CategoryId: 0,
          CategoryName: "No Available",
          Image: "assets/images/dev_images/noImageAvailable.jpg",
        }
        list.push(obj);
      }
      return list;
    }

  }
  imageClick(product) {
    if (product.CategoryId) {
      this.imageClickCallBack.emit(product);
    }
  }

}
