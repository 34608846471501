import { Component, Inject, OnInit } from '@angular/core';
import { LayoutService } from 'src/services/layout.service/layout.service';
import { CookieStorageService } from 'src/services/cookie-service/cookie.service';
import { MapsAPILoader } from '@agm/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-yess-layout',
  templateUrl: './yess-layout.component.html',
  styleUrls: ['./yess-layout.component.css']
})
export class YessLayoutComponent implements OnInit {
  isStoreLoaded: boolean = false;
  businessId: string = '';
  userId: any = {};
  latitude: number = 0;
  longitude: number = 0;
  zoom: number = 0;
  address: string = '';
  private geoCoder: google.maps.Geocoder | any;
  constructor(private _layoutService: LayoutService,
    private mapsAPILoader: MapsAPILoader,
    @Inject(DOCUMENT) private document: Document,
    private _cookieStorageService: CookieStorageService) {
    this.businessId = this._cookieStorageService.getStoreId();
    this.userId = this._cookieStorageService.getBuyerId();
    if (!this.userId) {
      this.userId = 0;
    }
  }

  ngOnInit() {
    var storeAddress = this._cookieStorageService.getCityAndPin();
    if (!storeAddress) {
      this.getDefaultStore(0);
      this.setCurrentLocation();
    } else {
      this.isStoreLoaded = true;
    }
  }
  private setCurrentLocation() {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.getAddress(this.latitude, this.longitude);
        });
      }
    });
  }
  getAddress(latitude: number, longitude: number) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results: any, status: any) => {
      if (status === 'OK') {
        if (results[0]) {
          this.address = results[0].formatted_address;
          let addressPin: string = '';
          let addresCity: string = '';
          results = results.filter(a => a.geometry)
          results[0].address_components.forEach(
            function (ac) {
              if (ac.types.includes("route")) {//area
              }
              if (ac.types.includes("locality")) {//city
                addresCity = ac.long_name;
              }
              if (ac.types.includes("administrative_area_level_1")) {//state
              }
              if (ac.types.includes("country")) {//country
              }
              if (ac.types.includes("postal_code")) {//pin
                addressPin = ac.long_name;
              }
            });
          if (addressPin) {
            this.getCurrentLocationStore(addressPin,addresCity)
          }
          else{
            if (addresCity) {
              this.getCurrentCityStore(addresCity);
            }
          }
        } else {
          window.alert('No location found');
        }
      } else {
        window.alert('Unable to get location due to: ' + status);
      }

    });
  }
  getCurrentLocationStore(pincode,addresCity) {
    this._layoutService.getDefaultStore(pincode).subscribe(res => {
      let result: any = res;
      if (!result.IsError) {
        if (result.ResponseMessage  && result.ResponseMessage[0]) {
          this._cookieStorageService.setStoreId(result.ResponseMessage[0].StoreId);
          this._cookieStorageService.setStoreInfo(result.ResponseMessage[0]);
          const selectedCityAndPin = {
            City: result.ResponseMessage[0].CityName,
            Pin: result.ResponseMessage[0].Pincode,
          }
          this._cookieStorageService.setCityAndPin(selectedCityAndPin);
          this.document.location.reload();
        }
        else{
          if (addresCity) {
            this.getCurrentCityStore(addresCity);
          }
        }
      }
      else{
        if (addresCity) {
          this.getCurrentCityStore(addresCity);
        }
      }
    });
  }
  getCurrentCityStore(addresCity) {
    this._layoutService.LoadArea('', addresCity)
      .subscribe(res => {
        let result: any = res;
        if (!result.IsError) {
          if (result.ResponseMessage && result.ResponseMessage[0]) {
            this._cookieStorageService.setStoreId(result.ResponseMessage[0].StoreId);
            this._cookieStorageService.setStoreInfo(result.ResponseMessage[0]);
            const selectedCityAndPin = {
              City: result.ResponseMessage[0].CityName,
              Pin: result.ResponseMessage[0].Pincode,
            }
            this._cookieStorageService.setCityAndPin(selectedCityAndPin);
            this.document.location.reload();
          }
        }

      }, err => {
        
      });
  }
  getDefaultStore(pincode) {
    this._layoutService.getDefaultStore(pincode).subscribe(res => {
      let result: any = res;
      if (!result.IsError) {
        this.isStoreLoaded = true;
        this._cookieStorageService.setStoreId(result.ResponseMessage[0].StoreId);
        this._cookieStorageService.setStoreInfo(result.ResponseMessage[0]);
        const selectedCityAndPin = {
          City: result.ResponseMessage[0].CityName,
          Pin: result.ResponseMessage[0].Pincode,
        }
        this._cookieStorageService.setCityAndPin(selectedCityAndPin);
      } else {
        this.isStoreLoaded = false;
      }
    }, err => {
      this.isStoreLoaded = false;
    })

  }

}
