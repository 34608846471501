import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'some'
})
export class SomePipe implements PipeTransform {
  transform(items: any[], field: string, value: string): boolean {
    if (!items) return true;
    if (!value) return true;
    return items.some(it => it[field]===value);
  }
}
