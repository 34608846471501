import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  removeSpace(value: string) {
    return value.replace(/\s/g, "");
  }
  ceil(value: any) {
    return Math.ceil(+value);
  }
  CalculatePercentage(OldPrice, NewPrice) {
    return (((NewPrice - OldPrice) / NewPrice) * 100).toFixed(2) + " %";
  }

}
