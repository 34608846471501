import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CookieStorageService } from 'src/services/cookie-service/cookie.service';
import { MyAccountService } from 'src/services/myaccount.service/myaccount.service';
import { YessEnums } from 'src/shared/enum/enum';
import { AddToCartModel } from 'src/models/category-models/category.models';
import { Subscription } from 'rxjs';
import { CategoryService } from 'src/services/category.service/category.service';
import { SharedService } from 'src/services/shared-service/shared.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { confirmOrderPopupComponent } from 'src/popups/confirm-order-popup/confirm-order-popup.component';

@Component({
  selector: 'app-my-order',
  templateUrl: './my-order.component.html',
  styleUrls: ['./my-order.component.css']
})
export class MyOrderComponent implements OnInit {
  cancelIndex: number = -1;
  isCancelOrderLoader: boolean = false;
  businessId: string = '';
  orderLength: number = 0;
  buyerId: any = '';
  pastOrders: any = [];
  canceledOrders: any = [];
  currentOrders: any = [];
  orderStatusEnum: any = {};
  addToCartServiceSubscription: Subscription;
  constructor(private toastr: ToastrService,
    private _cookieStorageService: CookieStorageService,
    private _myAccountService: MyAccountService,
    private _yessEnums: YessEnums,
    private _categoryService: CategoryService,
    private _sharedService: SharedService,
    private modalService: NgbModal,
    private _router: Router,) {
    this.buyerId = this._cookieStorageService.getBuyerId();
    this.businessId = this._cookieStorageService.getStoreId();
    this.orderStatusEnum = this._yessEnums.OrderStatusEnum;
  }

  ngOnInit() {
    this.getMyOrders(this.buyerId);
  }
  getMyOrders(buyerId) {
    this._myAccountService.getMyOrders(buyerId).subscribe(res => {
      let result: any = res;
      if (!result.IsError) {
        let Orders: any = [];
        Orders = result.ResponseMessage && result.ResponseMessage.length ? result.ResponseMessage : [];
        if (Orders && Orders.length) {
          this.orderLength = Orders.length;
          this.pastOrders = Orders.filter(x => x.ShippingStatus === this.orderStatusEnum.DELIVERED);
          this.canceledOrders = Orders.filter(x => x.ShippingStatus === this.orderStatusEnum.USERCANCELLED || x.ShippingStatus === this.orderStatusEnum.ADMINCANCELED);
          this.currentOrders = Orders.filter(x => x.ShippingStatus === this.orderStatusEnum.RECEIVED || x.ShippingStatus === this.orderStatusEnum.PROCESSING || x.ShippingStatus === this.orderStatusEnum.READYTOSHIP || x.ShippingStatus === this.orderStatusEnum.DISPATCHED);
          if (this.currentOrders && this.currentOrders.length) {
            this.currentOrders.forEach(res => {
              switch (res.ShippingStatus) {
                case this.orderStatusEnum.RECEIVED:
                  res.orderTimeline = 1;
                  break
                case this.orderStatusEnum.PROCESSING:
                  res.orderTimeline = 2;
                  break
                case this.orderStatusEnum.READYTOSHIP:
                  res.orderTimeline = 3;
                  break
                case this.orderStatusEnum.DISPATCHED:
                  res.orderTimeline = 4;
                  break
                case this.orderStatusEnum.DELIVERED:
                  res.orderTimeline = 5;
                  break

              }
            })
          }
        }
      } else {
        this.pastOrders = [];
        this.currentOrders = [];
      }
    })
  }
  cancelOrder(product: any, index) {
    // this.cancelIndex = index;
    // this.cancelOrderAPICall(product);
    this.openConfirmationPopup(product, index);
  }
  openConfirmationPopup(product: any, index) {
    const modalRef = this.modalService.open(confirmOrderPopupComponent,
      { centered: true, backdrop: 'static', keyboard: false, size: 'lg', windowClass: "" }
    );
    modalRef.componentInstance.message = "Do you wants to cancel this order";
    modalRef.result.then(() => {
      if (product) {
        this.cancelIndex = index;
        this.cancelOrderAPICall(product);
      }
    }, () => {
    });
  }
  cancelOrderAPICall(product: any) {
    const reqAPIData = {
      "BuyerId": product.BuyerId,
      "OrderId": product.OrderId
    }
    this.isCancelOrderLoader = true;
    this._myAccountService.cancelOrder(reqAPIData).subscribe(res => {
      let result: any = res;
      this.cancelIndex = -1;
      this.isCancelOrderLoader = false;
      if (result.IsError) {
        this.toastr.error(result.ErrorMessage);
      } else {
        this.toastr.success("Order cancelled successfully");
        this.getMyOrders(product.BuyerId);
      }
    }, err => {
      this.cancelIndex = -1;
      this.isCancelOrderLoader = false;
    });
  }
  addToCart(productToAdd: any) {
    const ssid: any = this._cookieStorageService.getSessionId();
    const product: AddToCartModel = {
      "StoreId": +this.businessId,
      "ProductId": +productToAdd.ProductId,
      "SessionId": ssid,
      "SKU": productToAdd.SKU ? productToAdd.SKU : "",
      "Qty": productToAdd.Quantity,
      "BuyerId": +this.buyerId ? this.buyerId : 0,
    }
    this.addToCartAPICall(product);

  }
  addToCartAPICall(addProduct: AddToCartModel) {

    if (this.addToCartServiceSubscription) {
      this.addToCartServiceSubscription.unsubscribe();
    }
    this.addToCartServiceSubscription = this._categoryService.addToCart(addProduct).subscribe(res => {
      let result: any = res;

      if (!result.IsError) {
        //this.toastr.success(result.ResponseMessage);
        this.toastr.success("Product successfully added to cart");
        this._sharedService.updateCartCount();
      } else {
        this.toastr.error(result.ErrorMessage);
        //this.toastr.error("Product not added to cart ");
      }
    })
  }

}
