import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { CookieStorageService } from 'src/services/cookie-service/cookie.service';

@Component({
  selector: 'app-yess-footer',
  templateUrl: './yess-footer.component.html',
  styleUrls: ['./yess-footer.component.css']
})
export class YessFooterComponent implements OnInit {
  CurrentYear: any = new Date().getFullYear();
  storeInfo: any = {};
  constructor(private _router: Router,
    private _cookieStorageService: CookieStorageService,) {
    this.storeInfo = _cookieStorageService.getStoreInfo();
  }

  ngOnInit() {
  }
  openMyOrders() {
    const url = '/my-orders';
    this._router.navigate([url]);
  }
  openAbout(pageName) {
    const url = 'about/' + pageName
    this._router.navigate([url]);
  }
  openMyAccount() {
    const url = '/my-account';
    this._router.navigate([url]);
  }
}
