import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CookieStorageService } from 'src/services/cookie-service/cookie.service';
import { AddToCartModel } from 'src/models/category-models/category.models';
import { Subscription } from 'rxjs';
import { CategoryService } from 'src/services/category.service/category.service';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/services/shared-service/shared.service';
import { SessionStorageService } from 'src/services/session-storage-service/session.storage.service';
import { Router, NavigationExtras } from '@angular/router';
import { DashboardService } from 'src/services/dashboard.service/dashboard.service';

@Component({
  selector: 'yess-offer-list',
  templateUrl: './yess-offer-list.component.html',
  styleUrls: ['./yess-offer-list.component.css']
})
export class YessOfferListComponent implements OnInit {
  businessId: string = '';
  addToCartServiceSubscription: Subscription;
  buyerId: number = 0;
  offerData: any = {};
  
  constructor(
    private _cookieStorageService: CookieStorageService,
    private _categoryService: CategoryService,
    private toastr: ToastrService,
    private _router: Router,
    private _sharedService: SharedService,
    private _sessionStorageService: SessionStorageService,
    private _dashboardService: DashboardService) {
    this.buyerId = this._cookieStorageService.getBuyerId();
    this.businessId = this._cookieStorageService.getStoreId();
    this.offerData = {
      offerSection: {
        offerImage: {},
        offerImages: []
      },
      organicfruits: {
        offerImage: {},
        offerProducts: [],
      },
      seasonMustHave: {
        offerImage: {},
        offerProducts: [],
      },
      drinkAndBaberages: {
        offerImage: {},
        offerProducts: [],
      },
      cleanAndHouseHold: {
        offerImage: {},
        offerProducts: [],
      },
      beautiAndCare: {
        offerImage: {},
        offerProducts: [],
      }

    }

  }

  ngOnInit() {
    this.getHomePageOffers(this.businessId);
  }
  getHomePageOffers(storeId) {
    this._dashboardService.getAllHomePageOffers(storeId).subscribe(res => {
      let result: any = res;
      if (!result.IsError) {
        const data = result.ResponseMessage;
        this.offerData = {
          offerSection: {
            offerImage: data.OfferSection1 ? data.OfferSection1 : {},
            offerProducts: data.OfferSectionItems1 ? data.OfferSectionItems1 : [],
          },
          organicfruits: {
            offerImage: data.OfferSection2 ? data.OfferSection2 : {},
            offerProducts: data.OfferSectionItems2 ? data.OfferSectionItems2 : [],
          },
          seasonMustHave: {
            offerImage: data.OfferSection3 ? data.OfferSection3 : {},
            offerProducts: data.OfferSectionItems3 ? data.OfferSectionItems3 : [],
          },
          drinkAndBaberages: {
            offerImage: data.OfferSection4 ? data.OfferSection4 : {},
            offerProducts: data.OfferSectionItems4 ? data.OfferSectionItems4 : [],
          },
          cleanAndHouseHold: {
            offerImage: data.OfferSection5 ? data.OfferSection5 : {},
            offerProducts: data.OfferSectionItems5 ? data.OfferSectionItems5 : [],
          },
          beautiAndCare: {
            offerImage: data.OfferSection6 ? data.OfferSection6 : {},
            offerProducts: data.OfferSectionItems6 ? data.OfferSectionItems6 : [],
          }
        }
      }
      this.offerData.offerSection.offerProducts = this.checkForAddDummyObject(this.offerData.offerSection.offerProducts);
      this.offerData.organicfruits.offerProducts = this.checkForAddDummyObject(this.offerData.organicfruits.offerProducts);
      this.offerData.seasonMustHave.offerProducts = this.checkForAddDummyObject(this.offerData.seasonMustHave.offerProducts);
      this.offerData.drinkAndBaberages.offerProducts = this.checkForAddDummyObject(this.offerData.drinkAndBaberages.offerProducts);
      this.offerData.cleanAndHouseHold.offerProducts = this.checkForAddDummyObject(this.offerData.cleanAndHouseHold.offerProducts);
      this.offerData.beautiAndCare.offerProducts = this.checkForAddDummyObject(this.offerData.beautiAndCare.offerProducts);

      this.offerData.offerSection.offerProducts.every(a=>{ a.Quantity=1 ;return true;});
      this.offerData.organicfruits.offerProducts.every(a=>{ a.Quantity=1 ;return true;});
      this.offerData.seasonMustHave.offerProducts.every(a=>{ a.Quantity=1 ;return true;});
      this.offerData.drinkAndBaberages.offerProducts.every(a=>{ a.Quantity=1 ;return true;});
      this.offerData.cleanAndHouseHold.offerProducts.every(a=>{ a.Quantity=1 ;return true;});
      this.offerData.beautiAndCare.offerProducts.every(a=>{ a.Quantity=1 ;return true;});
    })
  }
  checkForAddDummyObject(list) {
    if (list && list.length) {
      if (list.length < 8) {
        const length = 8 - list.length;
        return this.addDummyObjects(list, length);
      }
      else {
        return list;
      }
    } else {
      return this.addDummyObjects(list, 8);
    }
  }
  addDummyObjects(list: any = [], WantToAdd: number) {

    for (var i = 1; i <= WantToAdd; i++) {
      const obj = {
        ItemCode: 0,
        ItemName: "Not Available",
        ItemPhoto: "assets/images/dev_images/noImageAvailable.jpg",
        Price: null,
        SKU: "0",
        SellingPrice: null,
      }
      list.push(obj);
    }
    return list;


  }


  featuredRecipeClickCallBack(recipe) {
    switch (recipe.BannerType) {
      case "OFFER":
        if(recipe && recipe.ReferenceId && recipe.ReferenceCode){
        let navigationExtras: NavigationExtras = {
          queryParams: {
            "Type": 'Offer',
            "Data": JSON.stringify({
              "Id": recipe.ReferenceId,
              "Name": recipe.ReferenceCode,
            }),
          }
        };
        //this._router.navigate(['offers'], navigationExtras);
        this._router.navigate(['category/Offer/'+recipe.ReferenceCode+'/'+recipe.ReferenceId]);
      }
        break;
      case "BLOG":
        if(recipe && recipe.TargetUrl){
        const link = document.createElement('a');
        link.target = '_blank';
        link.href = recipe.TargetUrl;
        link.setAttribute('visibility', 'hidden');
        link.click();
        }
        break;
    }
  }
  SeasonsMustHaveImageClickCallBack(mustHave) {
    console.log(mustHave);
  }
  MainSliderImageClickCallBack(banner) {
    switch (banner.BannerType) {
      case "InnerBanner":
      case "HomePageBottomBanner":
      case "OFFER":
        let navigationExtras: NavigationExtras = {
          queryParams: {
            "Type": 'Offer',
            "Data": JSON.stringify({
              "Id": banner.ReferenceCode,
              "Name": banner.ReferenceCode,
            }),
          }
        };
        //this._router.navigate(['offers'], navigationExtras);
        this._router.navigate(['category/Offer/'+banner.ReferenceCode+'/'+banner.ReferenceCode]);
        break;
      case "BLOG":
        const link = document.createElement('a');
        link.target = '_blank';
        link.href = banner.TargetUrl;
        link.setAttribute('visibility', 'hidden');
        link.click();
        break;
    }
  }
  bankOfferClick(offer) {
    console.log(offer);
  }
  brandImageClickCallBack(category) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "Type": 'Brand',
        "Data": JSON.stringify({
          //"Id": category.CategoryId,
          "Name": category.BrandName,
          "Id": category.BrandId
        }),
      }
    };
    //this._router.navigate(['category'], navigationExtras);
    this._router.navigate(['category/Brand/'+category.BrandName+'/'+category.BrandId]);
  }
  cleanHouseHoldImageClickCallBack(product) {
    this.openProductDetails(product);
  }
  DrinkAndBeverageImageClick(product) {
    this.openProductDetails(product);
  }
  vegOfferCallBack(offer) {
    if (offer.OfferCode && offer.Offername) {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          "Type": 'Offer',
          "Data": JSON.stringify({
            "Id": offer.OfferCode,
            "Name": offer.Offername,
          }),
        }
      };
      //this._router.navigate(['offers'], navigationExtras);
      this._router.navigate(['category/Offer/'+offer.Offername+'/'+offer.OfferCode]);
    } else {
      //this._router.navigate(['page-not-found']);
    }
  }
  offerClickCallBack(offer) {debugger
    if (offer && offer.OfferCode && offer.Offername) {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          "Type": 'Offer',
          "Data": JSON.stringify({
            "Id": offer.OfferCode,
            "Name": offer.Offername,
          }),
        }
      };
      //this._router.navigate(['offers'], navigationExtras);
      this._router.navigate(['offers/Offer/'+offer.Offername+'/'+offer.OfferCode]);
    } else {
      //this._router.navigate(['page-not-found']);
    }
  }


  mainCategoryDoodleClick(category) {
    if (category && category.CategoryType === 'PARENTCATREGORY') {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          "Type": 'Category',
          "Data": JSON.stringify({
            "Id": category.CategoryId,
            "Name": category.CategoryName,
          }),
        }
      };
      //this._router.navigate(['category'], navigationExtras);
      this._router.navigate(['category/Category/'+category.CategoryName+'/'+category.CategoryId]);
    } else {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          "Type": 'SubCategory',
          "Data": JSON.stringify({
            "Id": category.CategoryId,
            "Name": category.CategoryName,
          }),
        }
      };
      //this._router.navigate(['category'], navigationExtras);
      this._router.navigate(['category/SubCategory/'+category.CategoryName+'/'+category.CategoryId]);
    }



  }
  addToCartCategoryItem(product: any) {
    this.addToCart(product);
  }
  fruitandVegAddToCart(product: any) {
    if (product.ItemName !== 'Not Available' && product.ProductId) {
      this.addToCart(product);
    } else {
      //this._router.navigate(['page-not-found']);
    }
  }
  offerAddToCart(product: any) {
    if (product.ItemName !== 'Not Available' && product.ProductId) {
      this.addToCart(product);
    } else {
      //this._router.navigate(['page-not-found']);
    }

  }
  addToCart(productToAdd: any) {
    const ssid: any = this._cookieStorageService.getSessionId();
    debugger
    const product: AddToCartModel = {
      "StoreId": +this.businessId,
      "ProductId": productToAdd.ProductId,
      "SessionId": ssid,
      "SKU": productToAdd.SKU ? productToAdd.SKU : "",
      "Qty": productToAdd.Quantity ? productToAdd.Quantity : 1,
      "BuyerId": this.buyerId ? this.buyerId : 0,
    }
    this.addToCartAPICall(product);
  }
  addToCartAPICall(addProduct: AddToCartModel) {
    if (this.addToCartServiceSubscription) {
      this.addToCartServiceSubscription.unsubscribe();
    }
    this.addToCartServiceSubscription = this._categoryService.addToCart(addProduct).subscribe(res => {
      let result: any = res;
      if (!result.IsError) {
        //this.toastr.success(result.ResponseMessage);
        this.toastr.success("Product successfully added to cart");
        this._sharedService.updateCartCount();
      } else {
        this.toastr.error(result.ErrorMessage);
      }
    })
  }
  openProductDetails(product: any) {
    
    if (product.ItemCode && product.CategoryId) {
      const url = "/product-detail/" + product.ItemCode + "/" + product.CategoryId;
      this._router.navigate([url]);
    } else {
      //this._router.navigate(['page-not-found']);
    }

  }

  

}
