import { Component, OnInit, ViewChild, ElementRef, Inject, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SignInSignUpPopupComponent } from 'src/popups/sign-in-sign-up-popup/sign-in-sign-up-popup.component';
import { SharedService } from 'src/services/shared-service/shared.service';
import { LayoutService } from 'src/services/layout.service/layout.service';
import { Subscription } from 'rxjs';
import { AddToCartModel, CategoryFilter } from 'src/models/category-models/category.models';
import { CategoryService } from 'src/services/category.service/category.service';
import { Router, NavigationExtras } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CookieStorageService } from 'src/services/cookie-service/cookie.service';
import { DOCUMENT } from '@angular/common';
import { YessConstants } from 'src/shared/constants';
import { CartSharedService } from 'src/yess-pages/yes-cart/cart-services/cart.shared.service';
@Component({
  selector: 'app-second-top-bar',
  templateUrl: './second-top-bar.component.html',
  styleUrls: ['./second-top-bar.component.css']
})
export class SecondTopBarComponent implements OnInit {
  @Output() openHeaderCategoryCallback = new EventEmitter();
  isLoading: boolean = false;
  businessId: string = '';
  buyerId: number = 0;
  searchText: string = "";
  loadSearchbarServiceSubscription: Subscription;
  @ViewChild('cartCount', { static: false }) cartCount: ElementRef;
  searchData: any = [];
  addToCartServiceSubscription: Subscription;
  categoryFilter = {} as CategoryFilter;
  constructor(private modalService: NgbModal,
    private _sharedService: SharedService,
    private _layoutService: LayoutService,
    private _categoryService: CategoryService,
    private _router: Router,
    private toastr: ToastrService,
    private _cookieStorageService: CookieStorageService,
    @Inject(DOCUMENT) private document: Document,
    private _cartSharedService: CartSharedService
  ) {
    const localbuyerId = _cookieStorageService.getBuyerId();
    this.buyerId = localbuyerId ? localbuyerId : 0
    this.businessId = this._cookieStorageService.getStoreId();
    this._sharedService.cartCount$.subscribe(res => {
      const ssid: any = this._cookieStorageService.getSessionId();
      this.updateCartCount(ssid, this.buyerId);
    });
  }
  resetFilter() {
    this.categoryFilter = {
      StoreId: +this.businessId,
      CategoryId: 0,
      SubCategoryId: 0,
      Brands: "",
      Manufacturers: "",
      PageSize: YessConstants.PageSize,
      PageNo: 1,
      Orderby: "Price",
      OrderDirection: "ASC",
      Priceranges: "",
      Offerid: 0,
      OfferCode: "",
      IsRefreshList: false,
      ProductNameSearch:"",
    }
  }
  ngOnInit() {
    this.resetFilter();
  }
  ngAfterViewInit() {
    const ssid: any = this._cookieStorageService.getSessionId();
    this.updateCartCount(ssid, this.buyerId);
  }
  loginSignUp(activeTab) {
    const modalRef = this.modalService.open(SignInSignUpPopupComponent,
      { centered: true, backdrop: 'static', keyboard: false, size: 'lg', windowClass: "loginModal" }
    );
    modalRef.componentInstance.activeTab = activeTab;
    modalRef.result.then((data) => {
      if (data && data.action) {
        this.document.location.reload();
      }
    }, (reason) => {
    });
  }
  updateCartCount(SessionId, BuyerId) {
    this._layoutService.getCartCount(SessionId, BuyerId).subscribe(res => {
      let result: any = res;
      if (!result.IsError) {
        this.cartCount.nativeElement.dataset.totalitems = result.ResponseMessage?result.ResponseMessage:'0';
        //this.cartCount.nativeElement.dataset.totalitems = result.TotalRecords?result.TotalRecords:'0';
      }
    })

  }
  onMainSearch() {
    this.categoryFilter.ProductNameSearch = this.searchText;
    if(this.searchText){
      this.getAllProducts(this.categoryFilter);
    }
    else {
      this.searchData = [];
    }
  }
  getAllProducts(categoryFilter: CategoryFilter) {
    this.isLoading = true;
    if (this.loadSearchbarServiceSubscription) {
      this.loadSearchbarServiceSubscription.unsubscribe();
    }
    this.loadSearchbarServiceSubscription = this._categoryService.getAllProducts(categoryFilter).subscribe(res => {
      let result: any = res;
      this.isLoading = false;
      if (!result.IsError) {
       // const productlist: [] = result.ResponseMessage && result.ResponseMessage.Filter_Manufacturers ? result.ResponseMessage.Filter_Manufacturers : [];
        const productlist: [] = result.ResponseMessage && result.ResponseMessage.Products ? result.ResponseMessage.Products : [];
        if (productlist.length) {
          this.searchData = productlist;
          this.searchData.forEach(res => {
            res.Quantity = 1;
          })
        } else {
          this.searchData = [];
        }
      }
    }, err => {
      this.isLoading = false;
    })
  }
  addToCart(productToAdd: any) {
    
    const ssid: any = this._cookieStorageService.getSessionId();
    const product: AddToCartModel = {
      "StoreId": +this.businessId,
      "ProductId": productToAdd.ProductId,
      //"ProductId": productToAdd.Id,
      "SessionId": ssid,
      "SKU": productToAdd.SKU ? productToAdd.SKU : "",
      "Qty": productToAdd.Quantity,
      "BuyerId": this.buyerId ? this.buyerId : 0,
    }
    this.addToCartAPICall(product);
  }
  addToCartAPICall(addProduct: AddToCartModel) {
    if (this.addToCartServiceSubscription) {
      this.addToCartServiceSubscription.unsubscribe();
    }
    this.addToCartServiceSubscription = this._categoryService.addToCart(addProduct).subscribe(res => {
      let result: any = res;
      if (!result.IsError) {
        //this.toastr.success(result.ResponseMessage);
        this.toastr.success("Product successfully added to cart");
        this._sharedService.updateCartCount();
        if (this._router.url === '/cart/main') {
          this._sharedService.updateCartPage();
          this._cartSharedService.updateTotalAmount();
        }
      } else {
        this.toastr.success(result.ErrorMessage);
        //this.toastr.success("Product not added to cart ");
      }
    })
  }
  OpenDetailPage(product: any) {
    const url = "/product-detail/" + product.ItemCode + "/" + product.CategoryId;
    this._router.navigate([url]);
    this.searchText="";
    this.searchData=[];
  }
  openCartPage() {
    this._router.navigate(['cart/main']);
  }
  openMyOrders() {
    this._router.navigate(['my-orders']);
  }

  logout() {
    this._cookieStorageService.clearAllCookies();
    localStorage.clear();
    sessionStorage.clear();
    this.document.location.reload();
  }
  viewAll() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "Type": 'SearchText',
        "Data": JSON.stringify({
          "Name": this.searchText,
        }),
      }
    };
    //this._router.navigate(['category'], navigationExtras);
    this._router.navigate(['category/SearchText/'+this.searchText]);
    this.searchData=[];
    this.searchText="";
  }
  openHeaderCategoryDropdown(){
    this.openHeaderCategoryCallback.emit();
  }
}
